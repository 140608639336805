import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutInAppComponent } from './layout-in-app/layout-in-app.component';
import { LayoutOutAppComponent } from './layout-out-app/layout-out-app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [LayoutInAppComponent, LayoutOutAppComponent],
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class LayoutModule {}
