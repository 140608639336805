import { Component } from '@angular/core';
import { ProviderService } from '../../../core/provider.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent {
  constructor(
    private providerService: ProviderService,
    private cookieService: CookieService
  ) {
    providerService.oAuthService.hasApplicationAccess().then((value) => {
      if (value) {
        this.providerService.utilService.navigateTo('/app/users');
      } else {
        this.cookieService.delete('MIDDLEWARE.refreshToken', '/');
        this.cookieService.delete('MIDDLEWARE.accessToken', '/');
        this.cookieService.delete('MIDDLEWARE.idToken', '/');
        this.cookieService.delete('MIDDLEWARE.code', '/');
        this.cookieService.delete('MIDDLEWARE.state', '/');
        this.cookieService.delete('MIDDLEWARE.whenToRefresh', '/');
      }
    });
  }

  logout(): void {
    this.providerService.oAuthService.logout();
  }
}
