<div class="selector">
  <form [formGroup]="exFormGroup">
    <div class="selector-label">
      <i class="{{icon}}"></i>
      <span>{{label}}</span>
    </div>
    <input type="text" formControlName="dataFilterController"
           [matAutocomplete]="auto" [disabled]="disabled">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith"
                      (optionsScroll)="onScroll()" (optionSelected)="optionSelected($event)">
      <mat-option *ngFor="let option of data$ | async" [value]="option">
        {{getOptionShowValue(option)}}
      </mat-option>
    </mat-autocomplete>
  </form>
</div>
