import { Injectable } from '@angular/core';
import { IAuthenticator } from '../../models/interfaces/i-authenticator';
import { IMapper } from '../../models/interfaces/i-mapper';
import { HttpVerb } from '../../models/http-verb';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private authenticator?: IAuthenticator;
  private headers: { [key: string]: string } = {};

  constructor() {
    // this.headers['Content-Type'] = 'application/json';
  }

  setAuthenticator(authenticator: IAuthenticator): void {
    this.authenticator = authenticator;
  }

  async get(
    url: string,
    mapper: IMapper,
    params?: { [key: string]: string }
  ): Promise<IMapper | any> {
    this.headers = {};
    try {
      const request = await this.authenticator?.getRequest(
        url,
        HttpVerb.get,
        this.headers,
        undefined,
        params
      );
      delete request?.headers?.host;
      const response = await axios.get(url, {
        headers: request?.headers,
        params,
      });
      return this.manageResponse(response, mapper);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.cause || error?.message);
    }
  }

  async post(
    url: string,
    bodyMapper: IMapper,
    responseMapper: IMapper
  ): Promise<IMapper | any> {
    this.headers = {};
    const body = bodyMapper.fillToJson();
    this.headers['Content-Type'] = 'application/json';
    try {
      const request = await this.authenticator?.getRequest(
        url,
        HttpVerb.post,
        this.headers,
        body
      );
      delete request?.headers?.host;
      const response = await axios.post(url, request.body, {
        headers: request?.headers,
      });
      return this.manageResponse(response, responseMapper);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async put(
    url: string,
    bodyMapper: IMapper,
    responseMapper: IMapper
  ): Promise<IMapper | any> {
    this.headers = {};
    const body = bodyMapper.fillToJson();
    this.headers['Content-Type'] = 'application/json';
    try {
      const request = await this.authenticator?.getRequest(
        url,
        HttpVerb.put,
        this.headers,
        body
      );
      delete request?.headers?.host;
      const response = await axios.put(url, request.body, {
        headers: request?.headers,
      });
      return this.manageResponse(response, responseMapper);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async delete(
    url: string,
    mapper: IMapper,
    params?: { [key: string]: string }
  ): Promise<IMapper | any> {
    this.headers = {};
    try {
      const request = await this.authenticator?.getRequest(
        url,
        HttpVerb.delete,
        this.headers,
        undefined,
        params
      );
      delete request?.headers?.host;
      const response = await axios.delete(url, {
        headers: request?.headers,
        params,
      });
      return this.manageResponse(response, mapper);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private async manageResponse(response: any, mapper: IMapper): Promise<any> {
    if (
      this.authenticator &&
      this.authenticator?.isAuthenticationError(response.status)
    ) {
      return this.authenticator?.manageError(response);
    } else if (response.status.toString().startsWith('2')) {
      return Promise.resolve(this.convertToObject(response.data, mapper));
    } else {
      return Promise.reject(
        `Status code: ${response.status} - error: ${response.data.responseMessage}`
      );
    }
  }

  private convertToObject(responseBody: any, mapper: IMapper): IMapper {
    if (responseBody) {
      mapper.fillFromJson(responseBody);
    }
    return mapper;
  }
}
