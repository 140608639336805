import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

export interface IAutoCompleteScrollEvent {
  autoComplete: MatSelect;
  scrollEvent: Event;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-select[optionsScroll]',
})
export class OptionsScrollMultiDirective implements OnDestroy {
  @Input() thresholdPercent = 0.8;
  @Output() optionsScroll = new EventEmitter<IAutoCompleteScrollEvent>();
  _onDestroy = new Subject();

  constructor(public matSelect: MatSelect) {
    this.matSelect._openedStream
      .pipe(
        tap(() => {
          /** Note: When autocomplete raises opened, panel is not yet created (by Overlay)
           Note: The panel will be available on next tick
           Note: The panel wil NOT open if there are no options to display
           */
          setTimeout(() => {
            /** Note: remove listner just for safety, in case the close event is skipped. */
            this.removeScrollEventListener();
            this.matSelect.panel.nativeElement.addEventListener(
              'scroll',
              this.onScroll.bind(this)
            );
          });
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();

    this.matSelect._closedStream
      .pipe(
        tap(() => this.removeScrollEventListener()),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  onScroll(event: any): void {
    if (this.thresholdPercent === undefined) {
      this.optionsScroll.next({
        autoComplete: this.matSelect,
        scrollEvent: event,
      });
    } else {
      const threshold =
        (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
      const current = event.target.scrollTop + event.target.clientHeight;
      if (current > threshold) {
        this.optionsScroll.next({
          autoComplete: this.matSelect,
          scrollEvent: event,
        });
      }
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
    this.removeScrollEventListener();
  }

  private removeScrollEventListener() {
    if (
      this.matSelect &&
      this.matSelect.panel &&
      this.matSelect.panel.nativeElement
    ) {
      this.matSelect.panel.nativeElement.removeEventListener(
        'onScroll',
        this.onScroll
      );
    }
  }
}
