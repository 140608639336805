<h1 mat-dialog-title>{{'CREATE_LINEA_LATTE.CREATE_LINEA_LATTE_TITLE' | i18next}}</h1>

<div mat-dialog-content>
  <form spellcheck="false" [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_LINEA_LATTE.SERIAL_NUMBER' | i18next}}</mat-label>
      <input matInput type="text" id="serialNumber" name="serialNumber" formControlName="serialNumber" placeholder="{{'CREATE_LINEA_LATTE.SERIAL_NUMBER' | i18next}}" value="">
      <mat-hint>{{'CREATE_LINEA_LATTE.SERIAL_NUMBER' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'serialNumber'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_LINEA_LATTE.SERIAL_NUMBER_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_LINEA_LATTE.EXPIRATION_DATE' | i18next}}</mat-label>
      <input matInput [matDatepicker]="datepicker" id="expirationDate" name="expirationDate" formControlName="expirationDate">
      <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
      <mat-hint>{{'CREATE_LINEA_LATTE.EXPIRATION_DATE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'expirationDate'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_LINEA_LATTE.EXPIRATION_DATE_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_LINEA_LATTE.PRODUCER_CODE' | i18next}}</mat-label>
      <input matInput type="text" id="producerCode" name="producerCode" formControlName="producerCode" placeholder="{{'CREATE_LINEA_LATTE.PRODUCER_CODE' | i18next}}" value="">
      <mat-hint>{{'CREATE_LINEA_LATTE.PRODUCER_CODE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'producerCode'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_LINEA_LATTE.PRODUCER_CODE_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_LINEA_LATTE.LAST_STATE' | i18next}}</mat-label>
      <mat-select id="lastState" formControlName="lastState" placeholder="{{'CREATE_LINEA_LATTE.LAST_STATE' | i18next}}" >
        <mat-option value="NOT_USED">
          {{'CREATE_LINEA_LATTE.NOT_USED' | i18next}}
        </mat-option>
        <mat-option value="USED">
          {{'CREATE_LINEA_LATTE.USED' | i18next}}
        </mat-option>
      </mat-select>
      <mat-hint>{{'CREATE_LINEA_LATTE.LAST_STATE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'lastState'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_LINEA_LATTE.LAST_STATE_ERROR' | i18next}}"></app-validator-ui>

  </form>
</div>
<div class="modal-dialog-actions" mat-dialog-actions>
  <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="createLineaLatte();" [disabled]="!formValid() || loading">
    <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
    <span>{{'CREATE_LINEA_LATTE.CREATE_LINEA_LATTE' | i18next}}</span>
  </button>
  <a mat-button (click)="closeModal()">
    <span>{{'CREATE_LINEA_LATTE.CANCEL' | i18next}}</span>
  </a>
</div>
