<div *ngIf="!isAuth0Loading || ((isAuth0Loading | async) === true)" class="app-loader">
  <div class="loader-element">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <img src="assets/images/logo-color.png">
  </div>
</div>
<ng-container *ngIf="isAuth0Loading && ((isAuth0Loading | async) === false)">
  <router-outlet></router-outlet>
</ng-container>

