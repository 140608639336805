import { Component, EventEmitter } from '@angular/core';
import { ApplicationModel } from '../../../../../shared/models/applications-models';
import { UserTypeModel } from '../../technicians/models/technician.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProviderService } from '../../core/provider.service';
import {
  InsertUserGQL,
  InsertUserMutationVariables,
  ListCompaniesGQL,
  ListUserTypesGQL,
  ListAreasGQL,
  ListApplicationsGQL,
  GetApplicationGQL,
} from '../../../graphql/generated';
import { InsertUserMapper, ListUserTypesMapper } from './create-user.mappers';
import { ListApplicationsMapper } from './mappers/applications.mappers';
import { LogLevel } from '../../models/log-level';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { CompanyModel } from '../../companies/models/company.model';
import { ListCompaniesMapper } from '../../companies/mappers/company.mapper';
import { AreasMapper } from './mappers/areas.mapper';
import { AreaModel } from './mappers/area.model';
import { companyChangedSubject } from '../navbar/navbar.component';
import { AntiMemLeak } from '../../shared/abstract-classes/anti-mem-leak';
import { ModifyUserAreaModel } from '../../../../../shared/models/area.model';
import { environment } from '../../../environments/environment';
import {
  GetApplicationMapper,
  GetApplicationReducedMapper,
} from '../modify-user/modify-user.mapper';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent extends AntiMemLeak {
  result: any = {};
  loading = false;
  listApplicationsMapper: ListApplicationsMapper = new ListApplicationsMapper();
  listAreasMapper: AreasMapper = new AreasMapper();
  selectedApplications: ApplicationModel[] = [];
  userTypes: UserTypeModel[] = [];
  selectedCompanies: CompanyModel[] = [];
  disabledChanged = new EventEmitter<boolean>();
  listCompaniesMapper = new ListCompaniesMapper();
  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])'
      ),
    ]),
  });
  private selectedUserRoles: { [applicationId: string]: UserTypeModel } = {};
  private selectedAreas: { [companyId: string]: ModifyUserAreaModel[] } = {};

  constructor(
    public providerService: ProviderService,
    private insertUser: InsertUserGQL,
    private listUserTypes: ListUserTypesGQL,
    public listApplications: ListApplicationsGQL,
    public listAreas: ListAreasGQL,
    public listCompaniesGQL: ListCompaniesGQL,
    private getApplication: GetApplicationGQL
  ) {
    super();
    this.getUserTypes();

    try {
      this.providerService.companyService.getSelectedCompany();
    } catch (e) {
      this.providerService.utilService.showMessage(
        _('MODIFY_USER.GET_SELECTED_USER_ERROR'),
        LogLevel.error
      );
      this.navigateToOrigin();
    }
    this.selectedCompanies = [];
    const selectedCompany =
      this.providerService.companyService.getSelectedCompany();
    if (selectedCompany) {
      this.selectedCompanies = [selectedCompany];
    }
    this.disabledChanged.emit(
      !this.providerService.companyService.getIsAdminSelected()
    );
    this.subscriptions.add(
      companyChangedSubject.subscribe((value: CompanyModel) => {
        this.disabledChanged.emit(
          !this.providerService.companyService.getIsAdminSelected()
        );
        if (value) {
          this.selectedCompanies = [value];
        }
      })
    );
    this.getSelectedApplication();
  }

  async getSelectedApplication(): Promise<void> {
    this.loading = true;
    this.selectedApplications = [
      await this.providerService.graphqlService.fetch(
        this.getApplication,
        { id: environment.purelatteApplication.id },
        new GetApplicationReducedMapper()
      ),
      await this.providerService.graphqlService.fetch(
        this.getApplication,
        { id: environment.purelatteApplication.mobileId },
        new GetApplicationReducedMapper()
      ),
    ];
    this.loading = false;
  }

  async getUserTypes(): Promise<void> {
    this.userTypes = await this.providerService.graphqlService.fetch(
      this.listUserTypes,
      {},
      new ListUserTypesMapper()
    );
  }

  formValid(): boolean {
    let areasSelected = true;
    if (Object.keys(this.selectedAreas).length > 0) {
      for (const key of this.selectedCompanies) {
        if (this.selectedAreas[key.id]) {
          if (this.selectedAreas[key.id].length === 0) {
            areasSelected = false;
          }
        }
      }
    } else {
      areasSelected = false;
    }
    let rolesSelected = true;
    if (Object.keys(this.selectedUserRoles).length > 0) {
      for (const key of this.selectedApplications) {
        if (!this.selectedUserRoles[key.applicationId]) {
          rolesSelected = false;
        }
      }
    } else {
      rolesSelected = false;
    }
    return this.form.valid && rolesSelected && areasSelected;
  }

  async addUser(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const selectedCompany: { companyId: string; areaIds: string[] }[] = [];
        for (const key in this.selectedAreas) {
          if (key) {
            const areas = this.selectedAreas[key].map((value) => value.id);
            if (areas.length > 0 && areas[0] === 'none') {
              selectedCompany.push({
                companyId: key,
                areaIds: [],
              });
            } else {
              selectedCompany.push({
                companyId: key,
                areaIds: this.selectedAreas[key].map((value) => value.id),
              });
            }
          }
        }
        const selectedUserRoles: {
          applicationId: string;
          userTypeId: string;
        }[] = [];
        for (const key in this.selectedUserRoles) {
          if (key) {
            selectedUserRoles.push({
              applicationId: key,
              userTypeId: this.selectedUserRoles[key].id,
            });
          }
        }
        const variables = {
          email: this.form.controls.email.value ?? '',
          name: this.form.controls.name.value ?? '',
          surname: this.form.controls.surname.value ?? '',
          userTypes: selectedUserRoles,
          companies: selectedCompany,
        } as InsertUserMutationVariables;

        await this.providerService.graphqlService.mutate(
          this.insertUser,
          variables,
          new InsertUserMapper()
        );
        this.providerService.utilService.showMessage(
          _('CREATE_USER.MODIFIED_CORRECTLY_MESSAGE'),
          LogLevel.success
        );
        this.navigateToOrigin();
      } catch (error: any) {
        this.providerService.utilService.showMessage(
          error.toString(),
          LogLevel.error
        );
      } finally {
        this.loading = false;
      }
    }
  }

  applicationsSelectionChanged($event: ApplicationModel[]): void {
    this.selectedApplications = $event;
  }

  areasSelectionChanged(company: CompanyModel, $event: AreaModel[]): void {
    if ($event.length > 0 && $event[0].id === 'none') {
      this.selectedAreas[company.id] = [
        {
          id: 'none',
          region: '',
          city: '',
          location: '',
        },
      ];
    } else {
      this.selectedAreas[company.id] = $event;
    }
  }

  navigateToOrigin(): void {
    this.providerService.utilService.navigateTo('app/users');
  }

  userRoleSelectionChanged(application: ApplicationModel, $event: any): void {
    this.selectedUserRoles[application.applicationId] = $event.value;
  }

  companySelectionChanged($event: any): void {
    this.selectedCompanies = $event;
  }
}
