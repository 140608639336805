import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ProviderService } from '../provider.service';
import { Observable } from 'rxjs';
import { UserModel } from '../../models/interfaces/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
  private environment = environment;
  private user?: UserModel;
  constructor(
    private router: Router,
    private providerService: ProviderService
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> {
    if (!this.user) {
      return new Promise((resolve, _reject) => {
        this.providerService.roleService
          .getUserAndRole()
          .then((userAndRole: UserModel) => {
            this.user = userAndRole;
            const userTypeName = userAndRole.userTypes.find(
              (value) =>
                value.applicationId === this.environment.purelatteApplication.id
            )?.userType.name;
            const isTechnicianOrUser =
              userTypeName === 'technician' || userTypeName === 'user';
            if (isTechnicianOrUser) {
              this.router.navigate(['/', 'app', 'profile']).then();
              resolve(false);
            } else {
              resolve(true);
            }
          });
      });
    } else {
      return new Promise((resolve, _reject) => {
        const userTypeName = this.user?.userTypes.find(
          (value) =>
            value.applicationId === this.environment.purelatteApplication.id
        )?.userType.name;
        const isTechnicianOrUser =
          userTypeName === 'technician' || userTypeName === 'user';
        if (isTechnicianOrUser) {
          this.router.navigate(['/', 'app', 'profile']).then();
          resolve(false);
        } else {
          resolve(true);
        }
      });
    }
  }
}
