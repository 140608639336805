import { Injectable } from '@angular/core';
import { UtilsService } from './app-utils/utils.service';
import { NetworkService } from './net-utils/network.service';
import { CognitoIamAuthenticatorService } from './net-utils/api-authorization/cognito-iam-authenticator.service';
import { TechnicianService } from '../technicians/services/technician.service';
import { CompanyService } from '../companies/services/company.service';
import { RoleService } from './net-utils/user-authentication/role.service';
import { PurelatteTranslateService } from '../translate/purelatte-translate-service/purelatte-translate.service';
import { GraphqlService } from './app-utils/graphql.service';
import { OAuthService } from './net-utils/user-authentication/auth0/o-auth-service/o-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor(
    private utilsService: UtilsService,
    private oAuthServiceInstance: OAuthService,
    private netService: NetworkService,
    private cognitoIamAuthenticatorService: CognitoIamAuthenticatorService,
    private companyServiceInstance: CompanyService,
    private technicianServiceInstance: TechnicianService,
    private roleServiceInstance: RoleService,
    private purelatteTranslateServiceInstance: PurelatteTranslateService,
    private graphqlServiceInstance: GraphqlService
  ) {}

  get oAuthService(): OAuthService {
    return this.oAuthServiceInstance;
  }

  get utilService(): UtilsService {
    return this.utilsService;
  }

  get networkService(): NetworkService {
    // This particular authenticator service requires the oauth service that encapsulate
    // the Okta Oauth SDK, we pass it to the singleton instance used by the provider service
    // because we use the provider service all around to avoid mismatching of context between
    // singletons.
    this.netService.setAuthenticator(this.cognitoIamAuthenticatorService);
    return this.netService;
  }

  get technicianService(): TechnicianService {
    return this.technicianServiceInstance;
  }

  get companyService(): CompanyService {
    return this.companyServiceInstance;
  }

  get roleService(): RoleService {
    return this.roleServiceInstance;
  }

  get translationService(): PurelatteTranslateService {
    return this.purelatteTranslateServiceInstance;
  }

  get graphqlService(): GraphqlService {
    return this.graphqlServiceInstance;
  }
}
