import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderService } from './core/provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // ===== Boostrap application data here =====
  // ==========================================
  isAuth0Loading: Observable<boolean>;
  constructor(private providerService: ProviderService) {
    this.isAuth0Loading = providerService.oAuthService.isLoadingSubject$;
    this.providerService.translationService.bootstrapCurrentLanguage();
  }
}
