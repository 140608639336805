import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { TableService } from './table.service';
import { Model } from '../../../../../../shared/core/model';
import { IExportDynamoMapper } from '../../../models/interfaces/i-export-dynamo-mapper';

export class TableDataSource<M extends Model> implements DataSource<M> {
  public loading$: Observable<boolean>;

  private tableSubject = new BehaviorSubject<M[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private tableService?: TableService;
  private lastEvaluatedKey: any[] = [];

  constructor() {
    this.loading$ = this.loadingSubject.asObservable();
  }

  setTableService(tableService: TableService): void {
    this.tableService = tableService;
  }

  connect(_: CollectionViewer): Observable<M[]> {
    return this.tableSubject.asObservable();
  }

  disconnect(_: CollectionViewer): void {
    this.tableSubject.complete();
    this.loadingSubject.complete();
  }

  async loadElements(
    url: string,
    parentId: number,
    filter = '',
    sortItem = '',
    sortDirection = 'asc',
    limit = 3,
    page = 0,
    mapper: IExportDynamoMapper
  ): Promise<void> {
    if (this.tableService) {
      try {
        this.loadingSubject.next(true);
        let lastKey;
        if (page !== 0 && this.lastEvaluatedKey[page - 1]) {
          lastKey = this.lastEvaluatedKey[page - 1];
        }
        const resMapper = await this.tableService.find(
          url,
          parentId,
          filter,
          sortItem,
          sortDirection,
          lastKey,
          limit,
          mapper
        );

        this.tableSubject.next(resMapper.elements);
        if (this.lastEvaluatedKey[page]) {
          this.lastEvaluatedKey[page] = resMapper.lastEvaluatedKey;
        } else {
          this.lastEvaluatedKey.push(resMapper.lastEvaluatedKey);
        }
      } catch (error: any) {
        this.tableSubject.next([]);
        throw new Error(error);
      } finally {
        this.loadingSubject.next(false);
      }
    }
  }
}
