<h1 mat-dialog-title>{{'CREATE_BIB.CREATE_BIB_TITLE' | i18next}}</h1>

<div mat-dialog-content>
  <form spellcheck="false" [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.PRODUCTION_DATE' | i18next}}</mat-label>
      <input matInput [matDatepicker]="datepicker" id="productionDate" name="productionDate" formControlName="productionDate">
      <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
      <mat-hint>{{'CREATE_BIB.PRODUCTION_DATE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'productionDate'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_BIB.PRODUCTION_DATE_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.DAILY_PRODUCTION_SEQUENCE' | i18next}}</mat-label>
      <input matInput type="text" id="dailyProductionSequence" name="dailyProductionSequence" formControlName="dailyProductionSequence" placeholder="{{'CREATE_BIB.DAILY_PRODUCTION_SEQUENCE' | i18next}}" value="">
      <mat-hint>{{'CREATE_BIB.DAILY_PRODUCTION_SEQUENCE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'dailyProductionSequence'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_BIB.DAILY_PRODUCTION_SEQUENCE_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.BIB_CAPACITY' | i18next}}</mat-label>
      <input matInput type="text" id="bibCapacity" name="bibCapacity" formControlName="bibCapacity" placeholder="{{'CREATE_BIB.BIB_CAPACITY' | i18next}}" value="">
      <mat-hint>{{'CREATE_BIB.BIB_CAPACITY' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'bibCapacity'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_BIB.BIB_CAPACITY_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.PRODUCER_CODE' | i18next}}</mat-label>
      <input matInput type="text" id="producerCode" name="producerCode" formControlName="producerCode" placeholder="{{'CREATE_BIB.PRODUCER_CODE' | i18next}}" value="">
      <mat-hint>{{'CREATE_BIB.PRODUCER_CODE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'producerCode'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_BIB.PRODUCER_CODE_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.MILK_TYPE' | i18next}}</mat-label>
      <input matInput type="text" id="milkType" name="milkType" formControlName="milkType" placeholder="{{'CREATE_BIB.MILK_TYPE' | i18next}}" value="">
      <mat-hint>{{'CREATE_BIB.MILK_TYPE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'milkType'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_BIB.MILK_TYPE_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.LAST_STATE' | i18next}}</mat-label>
      <mat-select id="lastState" formControlName="state" placeholder="{{'MODIFY_BIB.LAST_STATE' | i18next}}" >
        <mat-option value="NOT_USED">
          {{'MODIFY_BIB.NOT_USED' | i18next}}
        </mat-option>
        <mat-option value="USED">
          {{'MODIFY_BIB.USED' | i18next}}
        </mat-option>
      </mat-select>
      <mat-hint>{{'MODIFY_BIB.LAST_STATE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'state'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_BIB.LAST_STATE_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.EXPIRATION_DATE' | i18next}}</mat-label>
      <input matInput [matDatepicker]="datepickerProductionDate" id="expirationDate" name="expirationDate" formControlName="expirationDate">
      <mat-datepicker-toggle matIconSuffix [for]="datepickerProductionDate"></mat-datepicker-toggle>
      <mat-datepicker #datepickerProductionDate></mat-datepicker>
      <mat-hint>{{'CREATE_BIB.EXPIRATION_DATE' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'expirationDate'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_BIB.EXPIRATION_DATE_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.SERIAL_NUMBER_LINEA_LATTE' | i18next}}</mat-label>
      <input matInput type="text" id="serialNumberLineaLatte" name="serialNumberLineaLatte" formControlName="serialNumberLineaLatte" placeholder="{{'CREATE_BIB.SERIAL_NUMBER_LINEA_LATTE' | i18next}}" value="">
      <mat-hint>{{'CREATE_BIB.SERIAL_NUMBER_LINEA_LATTE' | i18next}}</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_BIB.PRODUCER_CODE_LINEA_LATTE' | i18next}}</mat-label>
      <input matInput type="text" id="producerCodeLineaLatte" name="producerCodeLineaLatte" formControlName="producerCodeLineaLatte" placeholder="{{'CREATE_BIB.PRODUCER_CODE_LINEA_LATTE' | i18next}}" value="">
      <mat-hint>{{'CREATE_BIB.PRODUCER_CODE_LINEA_LATTE' | i18next}}</mat-hint>
    </mat-form-field>
  </form>
</div>
<div class="modal-dialog-actions" mat-dialog-actions>
  <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="createBib();" [disabled]="!formValid() || loading">
    <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
    <span>{{'CREATE_BIB.CREATE_BIB' | i18next}}</span>
  </button>
  <a mat-button (click)="closeModal()">
    <span>{{'CREATE_BIB.CANCEL' | i18next}}</span>
  </a>
</div>
