<br><br>

<div class="align-right">
<!--[disabled]="!currentCompany"-->
  <button mat-raised-button color="{{currentCompany ? 'primary': 'disabled'}}"  (click)="addTech();">
    <i class="fa-solid fa-user-plus"></i>
    <span>{{ 'ADD_USERS' | i18next }}</span>
  </button>
</div>

<div class="header-container">
  <div class="header-text">
    <i class="fa-solid fa-circle-user"></i>
    <h1>{{ 'USERS' | i18next }}</h1>
  </div>

  <div class="search-form-container">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'SEARCH' | i18next }}</mat-label>
      <input matInput placeholder="Search items" (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
      <mat-hint>{{ 'FULL_TEXT_QUERY_PLACEHOLDER' | i18next }}</mat-hint>
    </mat-form-field>
  </div>

</div>
<app-graphql-table-ui
  *ngIf="dataSource"
  [datasource]="dataSource"
  [tableDefinitions]="tableDefinitions"
  [actionDefinitions]="actionDefinitions"
  [pageSize]="10"
  [pageSizeOptions]="[10,20,30]"
  [filterObservable]="filterObservable"
  [hasRowAction]="true"
  (rowClicked)="userRowClicked($event);">
</app-graphql-table-ui>

