<button mat-menu-item [matMenuTriggerFor]="languages">
  <i class="fa-solid fa-language"></i>
  <span>{{ 'LANGUAGES' | i18next }}</span>
</button>
<mat-menu #languages="matMenu" class="language-menu">
  <button mat-menu-item (click)="changeLanguage('it')">
    <img src="assets/images/languages/it.svg">
    <span>IT</span>
  </button>
  <button mat-menu-item (click)="changeLanguage('en')">
    <img src="assets/images/languages/en.svg">
    <span>EN</span>
  </button>
  <button mat-menu-item (click)="changeLanguage('fr')">
    <img src="assets/images/languages/fr.svg">
    <span>FR</span>
  </button>
</mat-menu>
