import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableDataSource } from '../table.datasource';
import { TableService } from '../table.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, skip, Subscription } from 'rxjs';
import { LogLevel } from '../../../../models/log-level';
import { ProviderService } from '../../../provider.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { IExportDynamoMapper } from '../../../../models/interfaces/i-export-dynamo-mapper';

@Component({
  selector: 'app-table-ui',
  templateUrl: './table-ui.component.html',
  styleUrls: ['./table-ui.component.scss'],
})
export class TableUiComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  @Input()
  backendUrl?: string;

  @Input()
  datasource?: TableDataSource<any>;

  @Input()
  mapper!: IExportDynamoMapper;

  @Input()
  actionTitle = _('ACTIONS');

  @Input()
  actionDefinitions?: {
    icon: string;
    text: string;
    styleClass: string;
    callback: any;
    tooltip?: string;
  }[];

  @Input()
  tableDefinitions?: {
    def: string;
    title: string;
    sortable?: boolean;
    prefix?: string;
    suffix?: string;
  }[];

  @Input()
  parentID?: number;

  @Input()
  pageSize = 3;

  @Input()
  pageSizeOptions: number[] = [3, 6, 8];

  @Input()
  filterObservable?: Observable<string>;

  @Input()
  changeBackendUrlSubject?: BehaviorSubject<string>;

  @Input()
  hasRowAction?: boolean = false;

  @Output()
  rowClicked: EventEmitter<any> = new EventEmitter();

  tableDefinitionHeaders?: string[];

  private refreshSubscription?: Subscription;
  private filterSubscription?: Subscription;
  private paginatorSubscription?: Subscription;
  private sortSubscription?: Subscription;
  private filter = '';

  constructor(
    public tableService: TableService,
    private providerService: ProviderService
  ) {}

  ngOnInit(): void {
    if (!this.datasource || !this.tableDefinitions) {
      throw new Error(
        'Verify that datasource and tableDefinitions are defined in the parent component'
      );
    }
    this.datasource.setTableService(this.tableService);
    this.tableDefinitionHeaders = this.tableDefinitions.map((i) => i.def);

    if (this.actionDefinitions && this.actionDefinitions.length > 0) {
      this.tableDefinitionHeaders =
        this.tableDefinitionHeaders.concat('actions');
    }
    this.refreshSubscription = this.changeBackendUrlSubject?.subscribe(
      (value: string) => {
        this.backendUrl = value;
        this.refreshData();
      }
    );
  }

  ngAfterViewInit(): void {
    this.paginatorSubscription = this.paginator.page.subscribe(() =>
      this.refreshData()
    );

    // reset the paginator after sorting
    this.sortSubscription = this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.refreshData();
    });

    this.filterSubscription = this.filterObservable
      ?.pipe(skip(1))
      .subscribe((value: string) => {
        this.filter = value;
        this.paginator.pageIndex = 0;
        this.refreshData();
      });

    setTimeout(() => this.refreshData(), 100);
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
    this.refreshSubscription?.unsubscribe();
    this.paginatorSubscription?.unsubscribe();
    this.sortSubscription?.unsubscribe();
  }

  rowClickedAction(row: any): void {
    this.rowClicked.emit(row);
  }

  private async refreshData(): Promise<void> {
    if (this.datasource && this.backendUrl && this.tableDefinitions) {
      try {
        await this.datasource.loadElements(
          this.backendUrl,
          this.parentID ?? -1,
          this.filter ?? '',
          this.sort.active,
          this.sort.direction,
          this.paginator.pageSize,
          this.paginator.pageIndex,
          this.mapper
        );
      } catch (error: any) {
        this.providerService.utilService.showMessage(
          error.message,
          LogLevel.error
        );
      }
    }
  }
}
