import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  skip,
  Subscription,
} from 'rxjs';
import { LogLevel } from '../../models/log-level';
import { GetTechnicianMapper } from '../../technicians/mappers/technician.mapper';
import { UserModel } from '../../technicians/models/technician.model';
import { companyChangedSubject } from '../navbar/navbar.component';
import { CompanyModel } from '../../companies/models/company.model';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { ListUsersGQL } from '../../../graphql/generated';
import { GraphqlTableDatasource } from '../../core/form-utils/graphql-table/graphql-table.datasource';

@Component({
  selector: 'app-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.scss'],
})
export class TechniciansComponent implements OnInit, OnDestroy {
  backendUrl = '';
  currentCompany?: CompanyModel;

  changeBackendUrlSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  dataSource?: GraphqlTableDatasource<UserModel>;

  techniciansMapper: GetTechnicianMapper = new GetTechnicianMapper();

  tableDefinitions = [
    { def: 'name', title: _('NAME'), sortable: true },
    { def: 'surname', title: _('SURNAME'), sortable: true },
    { def: 'email', title: _('EMAIL'), sortable: true },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen-to-square',
      text: _('USERS.EDIT_USER'),
      styleClass: 'table-action-edit',
      callback: (user: any): void => {
        this.modifyTechnician(user);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: _('USERS.DELETE_USER'),
      styleClass: 'table-action-delete',
      callback: (user: any): void => {
        this.deleteTechnician(user);
      },
    },
  ];

  filterObservable = new BehaviorSubject<string>('');

  debounceSubject = new BehaviorSubject<string>('');
  lastDebounceValue = '';
  filterSub?: Subscription;
  companyChangedSubscription?: Subscription;
  constructor(
    private providerService: ProviderService,
    private listUsers: ListUsersGQL
  ) {
    this.filterSub = this.debounceSubject
      .pipe(skip(1), debounceTime(500), distinctUntilChanged())
      .subscribe((value: any) => {
        this.filterObservable.next(value);
      });
  }
  sendFilterQuery(event: any): void {
    this.lastDebounceValue = event.target.value;
    this.debounceSubject.next(event.target.value);
  }
  ngOnDestroy(): void {
    this.filterSub?.unsubscribe();
    this.companyChangedSubscription?.unsubscribe();
  }

  addTech(): void {
    this.providerService.utilService.navigateTo('app/users/add-user');
  }

  async deleteTechnician(row: any): Promise<void> {
    try {
      await this.providerService.technicianService.deleteTechnician(row.userId);
      this.filterObservable.next(this.lastDebounceValue);
    } catch (error: any) {
      this.providerService.utilService.showMessage(
        error.toString(),
        LogLevel.error
      );
    }
  }
  ngOnInit(): void {
    const selectedCompany =
      this.providerService.companyService.getSelectedCompany();
    if (selectedCompany) {
      this.currentCompany = selectedCompany;
      let variables = {};
      if (!this.providerService.companyService.getIsAdminSelected()) {
        variables = {
          companyId: selectedCompany.id,
        };
      }
      this.dataSource = new GraphqlTableDatasource<UserModel>(
        this.providerService,
        this.listUsers,
        this.techniciansMapper,
        variables
      );
    }

    this.companyChangedSubscription = companyChangedSubject
      .pipe(skip(1))
      .subscribe((company) => {
        if (company) {
          this.currentCompany = company;
          const variables: { [key: string]: string } = {};
          if (!this.providerService.companyService.getIsAdminSelected()) {
            variables['companyId'] = company.id;
          }
          if (!this.dataSource) {
            this.dataSource = new GraphqlTableDatasource<UserModel>(
              this.providerService,
              this.listUsers,
              this.techniciansMapper,
              variables
            );
          } else {
            this.dataSource.changeVariables(variables);
          }
        }
      });
  }

  userRowClicked(row: any): void {
    this.modifyTechnician(row);
  }

  private modifyTechnician(user: any) {
    this.providerService.technicianService.setSelectedTechnician(user);
    this.providerService.companyService.setFromCompanyDetails(false);
    if (this.currentCompany) {
      this.providerService.companyService.setSelectedCompany(
        this.currentCompany
      );
    }
    this.providerService.utilService.navigateTo('app/users/modify-user');
  }
}
