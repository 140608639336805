import { Injectable } from '@angular/core';
import { IAuthenticator } from '../../../models/interfaces/i-authenticator';
import { HttpVerb } from '../../../models/http-verb';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-browser';
import {
  CognitoIdentity,
  GetIdCommandOutput,
} from '@aws-sdk/client-cognito-identity';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from '../user-authentication/auth0/o-auth-service/o-auth.service';

export enum RequestType {
  rest,
  graphql,
}

@Injectable({
  providedIn: 'root',
})
export class CognitoIamAuthenticatorService implements IAuthenticator {
  // How to solve proper signing: https://github.com/aws/aws-sdk-js-v3/issues/3590
  sigV4?: SignatureV4;
  cognitoIdentity?: CognitoIdentity;
  idToken = '';

  constructor(
    private cookieService: CookieService,
    private oauthService: OAuthService
  ) {}

  async getRequest(
    path: string,
    method: HttpVerb,
    headers: { [key: string]: string },
    body?: string,
    params?: { [key: string]: string },
    type?: RequestType
  ): Promise<any> {
    if (type) {
      await this.prepareSignatureV4(type);
    } else {
      await this.prepareSignatureV4(RequestType.rest);
    }

    const apiUrl = new URL(path);
    headers['host'] = apiUrl.hostname;

    const objectToSign = {
      method: method.toUpperCase(),
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      protocol: apiUrl.protocol,
      query: params,
      body,
      headers,
    };

    return await this?.sigV4?.sign(objectToSign, { signingDate: new Date() });
  }

  isAuthenticationError(statusCode: number): boolean {
    return statusCode === 403;
  }

  manageError(response: any): Promise<any> {
    return Promise.reject(
      `Status code: ${response.statusCode} - error: ${response.body}`
    );
  }

  private async prepareSignatureV4(type: RequestType): Promise<void> {
    try {
      const token = await this.oauthService.getIdToken();
      if (token) {
        try {
          // How to define logins Map:
          // https://docs.aws.amazon.com/cognitoidentity/latest/APIReference/API_GetId.html#API_GetId_RequestSyntax
          // Check the LoginsMap information in the document, basically you have to use the domain value for the key
          // of the map and the id token for the value.
          const logins: { [key: string]: string } = {};
          logins[environment.auth0.domain] = token;
          this.idToken = token;

          this.cognitoIdentity = new CognitoIdentity({
            region: 'eu-west-1',
          });
          let getCredentialsForIdentityCommandOutput: any;
          let credentials = '';
          if (type === RequestType.rest) {
            credentials = this.cookieService.get(
              'credential-for-identity-purelatte-rest'
            );
          } else {
            credentials = this.cookieService.get(
              'credential-for-identity-purelatte-graphql'
            );
          }
          if (credentials) {
            getCredentialsForIdentityCommandOutput = JSON.parse(credentials);
          } else {
            // 1) Get an identity from the identity pool of Cognito based on the grant given by the id token of the external IdP.
            const getIdCommandOutput: GetIdCommandOutput =
              await this.cognitoIdentity?.getId({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                IdentityPoolId:
                  type === RequestType.rest
                    ? environment.cognito.identityPoolIdPureLatte
                    : environment.cognito.identityPoolId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Logins: logins,
              });

            // 2) Based on the identity generate a set of temporary credentials.
            getCredentialsForIdentityCommandOutput =
              await this.cognitoIdentity?.getCredentialsForIdentity({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                IdentityId: getIdCommandOutput.IdentityId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Logins: logins,
              });
            if (type === RequestType.rest) {
              this.cookieService.set(
                'credential-for-identity-purelatte-rest',
                JSON.stringify(getCredentialsForIdentityCommandOutput),
                getCredentialsForIdentityCommandOutput.Credentials?.Expiration,
                '/'
              );
            } else {
              this.cookieService.set(
                'credential-for-identity-purelatte-graphql',
                JSON.stringify(getCredentialsForIdentityCommandOutput),
                getCredentialsForIdentityCommandOutput.Credentials?.Expiration,
                '/'
              );
            }
          }
          let service = '';
          if (type === RequestType.graphql) {
            service = 'appsync';
          } else {
            service = 'execute-api';
          }
          // 3) Given the credentials, set up the Signature V4 library to execute apis in a particular region using those credentials.
          this.sigV4 = new SignatureV4({
            service,
            region: 'eu-west-1',
            credentials: {
              accessKeyId:
                getCredentialsForIdentityCommandOutput.Credentials
                  ?.AccessKeyId ?? '',
              secretAccessKey:
                getCredentialsForIdentityCommandOutput.Credentials?.SecretKey ??
                '',
              sessionToken:
                getCredentialsForIdentityCommandOutput.Credentials
                  ?.SessionToken ?? '',
            },
            sha256: Sha256,
          });
          return Promise.resolve();
        } catch (error2) {
          return Promise.reject(error2);
        }
      } else {
        return Promise.reject('no token retrieved');
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
