import { Injectable } from '@angular/core';
import { UserModel } from '../../../models/interfaces/user.model';
import { GraphqlService } from '../../app-utils/graphql.service';
import { GetUserMapper } from 'src/app/mappers/user.mapper';
import { GetUserGQL } from '../../../../graphql/generated';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(
    private getUser: GetUserGQL,
    private graphqlService: GraphqlService
  ) {}

  async getUserAndRole(): Promise<UserModel> {
    return this.graphqlService.fetch<UserModel>(
      this.getUser,
      {},
      new GetUserMapper()
    );
  }
}
