import { Component, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TableDataSource } from '../../core/form-utils/table/table.datasource';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  skip,
  Subscription,
} from 'rxjs';
import { ProviderService } from '../../core/provider.service';
import { LogLevel } from '../../models/log-level';
import { BibMapper } from './bib-mappers';
import { BibModel } from './bib.models';
import { NetworkService } from '../../core/net-utils/network.service';
import { CreateBibComponent } from '../dialogs/create-bib/create-bib.component';
import { ModifyBibComponent } from '../dialogs/modify-bib/modify-bib.component';

@Component({
  selector: 'app-bib',
  templateUrl: './bib.component.html',
  styleUrls: ['./bib.component.scss'],
})
export class BibComponent implements OnDestroy {
  backendUrl = environment.api.endpointPurelatte + 'bib';

  dataSource: TableDataSource<BibModel> = new TableDataSource<BibModel>();

  bibMapper: BibMapper = new BibMapper();

  tableDefinitions = [
    {
      def: 'productionDate',
      title: _('BIB.PRODUCTION_DATE'),
      sortable: false,
    },
    {
      def: 'dailyProductionSequence',
      title: _('BIB.DAILY_PRODUCTION_SEQUENCE'),
      sortable: false,
    },
    {
      def: 'bibCapacity',
      title: _('BIB.BIB_CAPACITY'),
      sortable: false,
    },
    {
      def: 'producerCode',
      title: _('BIB.PRODUCER_CODE'),
      sortable: false,
    },
    {
      def: 'milkType',
      title: _('BIB.MILK_TYPE'),
      sortable: false,
    },
    {
      def: 'state',
      title: _('BIB.STATE'),
      sortable: false,
    },
    {
      def: 'expirationDate',
      title: _('BIB.EXPIRATION_DATE'),
      sortable: false,
    }
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: _('BIB.MODIFY_BIB'),
      styleClass: 'table-action',
      callback: (row: any): void => {
        this.modifyBib(row);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: _('BIB.DELETE_BIB'),
      styleClass: 'table-action-delete',
      callback: (row: any): void => {
        this.deleteBib(row);
      },
    },
  ];

  filterObservable = new BehaviorSubject<string>('');
  debounceSubject = new BehaviorSubject<string>('');
  lastDebounceValue = '';
  filterSub?: Subscription;
  lastState: any;

  constructor(
    private providerService: ProviderService,
    private networkService: NetworkService
  ) {
    this.filterSub = this.debounceSubject
      .pipe(skip(1), debounceTime(500), distinctUntilChanged())
      .subscribe((value: any) => {
        this.filterObservable.next(value);
      });
  }

  sendFilterQuery(event: any): void {
    console.log('sendFilterQuery', event);
    this.lastDebounceValue = event;
    this.debounceSubject.next(event);
  }

  ngOnDestroy(): void {
    this.filterSub?.unsubscribe();
  }

  addBibCallback(): void {
    this.filterObservable.next(this.lastDebounceValue);
  }

  addBib(): void {
    this.providerService.utilService.openDialog(
      CreateBibComponent,
      {
        callback: () => {
          this.addBibCallback();
        },
      },
      'create-modal'
    );
  }

  async modifyBib(row: any): Promise<void> {
    this.providerService.utilService.openDialog(
      ModifyBibComponent,
      {
        row,
        callback: () => {
          this.addBibCallback();
        },
      },
      'create-modal'
    );
  }

  async deleteBib(row: any): Promise<void> {
    const responseMapper = new BibMapper();
    try {
      await this.networkService?.delete(
        this.backendUrl + '/' + row.pk.replace(/#/g, '_').replace(/\//g, '-'),
        responseMapper
      );
      this.filterObservable.next(this.lastDebounceValue);
      this.providerService.utilService.showMessage(
        'BIB deleted successfully!',
        LogLevel.success
      );
    } catch (error: any) {
      this.providerService.utilService.showMessage(
        error.toString(),
        LogLevel.error
      );
    }
  }
}
