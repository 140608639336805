<div class="modify-user-container">
  <button mat-raised-button color="primary" class="navigate-back-button" (click)="navigateToOrigin()">
    <i class="fa-solid fa-arrow-left"></i>
    <span>{{'MODIFY_USER.BACK' | i18next}}</span>
  </button>
  <div class="modify-user-form">

    <ng-container *ngIf="initiating || loading">
      <mat-spinner></mat-spinner>
    </ng-container>
    <h1 class="modify-user-title">{{'MODIFY_USER.TITLE' | i18next}}</h1>

    <form spellcheck="false" [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{'MODIFY_USER.NAME_LABEL' | i18next}}</mat-label>
        <input matInput type="text" id="name" name="name" formControlName="name" placeholder="{{'MODIFY_USER.NAME_PLACEHOLDER' | i18next}}" value="">
        <mat-hint>{{'MODIFY_USER.NAME_HINT' | i18next}}</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_USER.NAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>{{'MODIFY_USER.SURNAME' | i18next}}</mat-label>
        <input matInput type="text" id="surname" name="surname" formControlName="surname" placeholder="{{'MODIFY_USER.SURNAME_PLACEHOLDER' | i18next}}" value="">
        <mat-hint>{{'MODIFY_USER.SURNAME_HINT' | i18next}}</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'surname'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_USER.SURNAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>
    </form>
  </div>
  <div class="modify-user-options-body">
    <h2 class="modify-user-options-title">{{'MODIFY_USER.COMPANIES_AREAS_TITLE' | i18next}}</h2>
    <app-select-infinite-scroll-multi
      *ngIf="!initiating"
      [icon] = "'fa-solid fa-buildings'"
      label = "{{'MODIFY_USER.COMPANIES_LABEL' | i18next}}"
      [dataShowSelectors] = "['name']"
      [dataCompareSelectors] = "['id']"
      [query] = "listCompaniesGQL"
      [mapper] = "listCompaniesMapper"
      [params] = "{}"
      [selectedData]="selectedCompanies"
      [disabled] = true
      [disabledChanged]="companySelectionDisabled"
      (selectionChanged)="companySelectionChanged($event)">
    </app-select-infinite-scroll-multi>

    <div class="options-container-header" *ngIf="selectedCompanies.length > 0">
      <div class="option-row title">
        <div class="options-name">
          <span>Company:</span>
        </div>
        <div class="option-value">
          <span>Areas:</span>
        </div>
      </div>
    </div>
    <div *ngFor="let company of selectedCompanies" class="applications-container">
      <div class="option-row">
        <div class="options-name">
          <span>{{company.name}}:</span>
        </div>
        <div class="option-value">
          <app-select-infinite-scroll-multi
            *ngIf="companyHasNoneOption[company.id] !== undefined"
            [icon] = "'fa-solid fa-location-dot'"
            label = "{{'MODIFY_USER.AREAS_LABEL' | i18next}}"
            [dataShowSelectors] = "['region', 'city', 'location']"
            [dataCompareSelectors]="['id']"
            [query] = "listAreas"
            [mapper] = "listAreasMapper"
            [params] = "{companyId: company.id , userId: currentUser?.userId ?? ''}"
            [selectedData]="selectedAreas[company.id]"
            [noneOption] = companyHasNoneOption[company.id]
            [disabledChanged]="areaSelectionDisabled"
            (selectionChanged)="areasSelectionChanged(company, $event)">
          </app-select-infinite-scroll-multi>
        </div>
      </div>
    </div>
  </div>
  <div class="modify-user-options-body last">
    <h2 class="modify-user-options-title">{{'MODIFY_USER.APPLICATIONS_USER_TYPES_TITLE' | i18next}}</h2>
    <app-select-infinite-scroll-multi
      *ngIf="!initiating"
      [icon] = "'fa-solid fa-grid'"
      label = "{{'MODIFY_USER.APPLICATIONS_LABEL' | i18next}}"
      [dataShowSelectors] = "['applicationName']"
      [dataCompareSelectors]="['applicationId']"
      [query] = "listApplications"
      [mapper] = "listApplicationsMapper"
      [selectedData]="selectedApplications"
      [disabled]="true"
      [disabledChanged]="applicationSelectionDisabled"
      [params] = ""
      (selectionChanged)="applicationsSelectionChanged($event)">
    </app-select-infinite-scroll-multi>
    <div class="options-container-header" *ngIf="selectedApplications.length > 0">
      <div class="option-row title">
        <div class="options-name">
          <span>Application:</span>
        </div>
        <div class="option-value">
          <span>User type:</span>
        </div>
      </div>
    </div>
    <div *ngFor="let application of selectedApplications" class="applications-container">
      <div class="option-row">
        <div class="options-name">
          <span>{{application.applicationName}}:</span>
        </div>
        <div class="option-value">
          <mat-form-field appearance="fill" (selectionchange)="userRoleSelectionChanged(application, $event)">
            <mat-label>{{'MODIFY_USER.USER_ROLE_LABEL' | i18next}}</mat-label>
            <mat-select [(ngModel)]="selectedUserRoles[application.applicationId].id" [disabled]="userRoleSelectionDisabled">
              <mat-option *ngFor="let userType of userTypes" [value]="userType.id">{{providerService.utilService.toCapitalizedWords(userType.name)}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modify-button-container">
  <button mat-mini-fab color="{{initiating ? 'disabled': 'primary'}}" type="button" (click)="enableAllOptions();" *ngIf="!inEditingMode" [disabled]="initiating" matTooltip="{{'MODIFY_USER.EDIT_TOOLTIP' | i18next}}">
    <i class="fa-solid fa-pen"></i>
  </button>
  <button mat-mini-fab color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" *ngIf="inEditingMode" (click)="modifyUser();" [disabled]="!formValid() || loading" matTooltip="{{'MODIFY_USER.MODIFY_USER_TOOLTIP' | i18next}}">
    <i class="fa-solid fa-upload"></i>
  </button>
  <button mat-mini-fab color="{{!loading ? 'primary': 'disabled'}}" type="button" (click)="disableAllOptions();" *ngIf="inEditingMode" [disabled]="loading"  matTooltip="{{'MODIFY_USER.CANCEL_TOOLTIP' | i18next}}">
    <i class="fa-solid fa-x"></i>
  </button>
</div>
