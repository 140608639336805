import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ProviderService } from '../provider.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private providerService: ProviderService) {}

  async canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const isLoading = await firstValueFrom(
      this.providerService.oAuthService.isLoadingSubject$
    );
    if (
      !(await this.providerService.oAuthService.hasValidIdToken()) &&
      !isLoading &&
      !this.providerService.oAuthService.hasError
    ) {
      this.providerService.utilService.navigateTo('/auth/sign-in');
      return false;
    } else {
      if (!(await this.providerService.oAuthService.hasApplicationAccess())) {
        this.providerService.utilService.navigateTo('/not-authorized');
      }
      return true;
    }
  }
}
