import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';
import { UserTypeModel, UserModel } from '../models/technician.model';
import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';

export class GetUserTypesMapper extends IGraphqlMapper {
  elements: UserTypeModel[] = [];

  returnFromJson(json: any): UserTypeModel[] {
    this.elements = [];
    const data = json['listUserTypes'];
    data.forEach((userType: any) => {
      this.elements.push({
        id: userType['id'],
        name: userType['name'],
        idpIdentifier: userType['idpIdentifier'],
      });
    });
    return this.elements;
  }

  fillToJson(): string {
    return JSON.stringify(this.elements);
  }
}

export class DeleteUserMapper implements IGraphqlMapper {
  result = false;
  returnFromJson(json: any): boolean {
    const value = json['deleteUser'];
    this.result = value;
    return this.result;
  }
}

export class GetTechnicianMapper extends IGraphqlPagedMapper {
  items: UserModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: UserModel[]; total: number } {
    this.items = [];
    let pagedUser = [];

    pagedUser = json['listUsers'];

    pagedUser['items'].forEach((value: any) => {
      this.items.push({
        userId: value['id'],
        name: value['name'],
        surname: value['surname'],
        email: value['email'],
        idpIdentifier: value['idp_identifier'],
        userTypes: value['userTypes'].length > 0 ? value['userTypes'] : [],
        companies: value['companies'].length > 0 ? value['companies'] : [],
      });
    });
    this.total = pagedUser['total'];
    return { items: this.items, total: this.total };
  }
}
