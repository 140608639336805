import { IExportMapper } from '../../models/interfaces/i-export-mapper';
import { LineaLatteModel } from './linea-latte.models';
import { IMapper } from '../../models/interfaces/i-mapper';
import { IExportDynamoMapper } from '../../models/interfaces/i-export-dynamo-mapper';

export class ListLineaLatteMapper implements IExportDynamoMapper {
  elements: LineaLatteModel[] = [];
  lastEvaluatedKey: any;
  total = 0;

  fillFromJson(json: any): void {
    this.elements = [];
    const data = json['items'];
    data.forEach((lineaLatte: any) => {
      this.elements.push({
        pk: lineaLatte['pk'],
        serialNumber: lineaLatte['serialNumber'],
        expirationDate: lineaLatte['expirationDate'],
        producerCode: lineaLatte['producerCode'],
        state: lineaLatte['lastState'],
      });
    });
    this.lastEvaluatedKey = json['lastEvaluatedKey'];
    this.total = json['totalCount'];
  }

  fillToJson(): string {
    return JSON.stringify(this.elements);
  }
}

export class GetLineaLatteMapper implements IMapper {
  pk?: string;
  serialNumber?: string;
  expirationDate?: string;
  producerCode?: string;
  state?: string;

  fillFromJson(json: any): void {
    this.pk = json['pk'];
    this.serialNumber = json['serialNumber'];
    this.expirationDate = json['expirationDate'];
    this.producerCode = json['producerCode'];
    this.state = json['lastState'];
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }
}

export class DeleteLinearLatteMapper implements IExportMapper {
  elements: string[] = [];
  fillFromJson(json: any): void {
    this.elements.push(json);
  }

  fillToJson(): string {
    return JSON.stringify(this.elements);
  }
}

export class CreateLineaLatteMapper implements IMapper {
  pk?: string;
  serialNumber?: string;
  expirationDate?: Date;
  producerCode?: string;
  lastState?: string;

  fillFromJson(json: any): void {
    this.pk = json['pk'];
    this.serialNumber = json['serialNumber'];
    this.expirationDate = json['expirationDate'];
    this.producerCode = json['producerCode'];
    this.lastState = json['lastState'];
  }

  fillToJson(): string {
    this.expirationDate?.setHours(0, 0, 0, 0);
    const formattedExpirationDate = this.expirationDate
      ? `${this.expirationDate.getDate().toString().padStart(2, '0')}/${(
          this.expirationDate.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${this.expirationDate.getFullYear()}`
      : null;
    const result = {
      serialNumber: this.serialNumber,
      expirationDate: formattedExpirationDate,
      producerCode: this.producerCode,
      lastState: this.lastState,
    };
    return JSON.stringify(result);
  }
}
