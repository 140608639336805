import { Injectable } from '@angular/core';
import { Constants } from '../../models/constants';
import { NetworkService } from '../../core/net-utils/network.service';
import { IMapper } from '../../models/interfaces/i-mapper';
import {
  DeleteUserMapper,
  GetTechnicianMapper,
  GetUserTypesMapper,
} from '../mappers/technician.mapper';
import {
  ListUserTypesGQL,
  InsertUserGQL,
  DeleteUserGQL,
  GetUserGQL,
} from '../../../graphql/generated';
import {
  UserTypeModel,
  TechnicianModel,
  UserModel,
} from '../models/technician.model';
import { GraphqlService } from '../../core/app-utils/graphql.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class TechnicianService {
  private selectedUser?: UserModel;

  constructor(
    private graphqlService: GraphqlService,
    private listUserTypes: ListUserTypesGQL,
    private insertUser: InsertUserGQL,
    private deleteUser: DeleteUserGQL,
    private getUser: GetUserGQL
  ) {}

  setSelectedTechnician(user: UserModel): void {
    this.selectedUser = user;
  }

  getSelectedTechnician(): UserModel {
    if (this.selectedUser) {
      return this.selectedUser;
    } else {
      throw new Error(_('USER_SERVICE.NO_USER_SELECTED_ERROR'));
    }
  }
  async deleteTechnician(id: string): Promise<void> {
    await this.graphqlService.mutate(
      this.deleteUser,
      { id },
      new DeleteUserMapper()
    );
  }

  async getUserTypes(): Promise<UserTypeModel[]> {
    const mapper = new GetUserTypesMapper();
    return await this.graphqlService.fetch<UserTypeModel[]>(
      this.listUserTypes,
      {},
      mapper
    );
  }

  async getUserById(id: string): Promise<TechnicianModel> {
    id = id.replace('|', '%7C');
    return await this.graphqlService.fetch<TechnicianModel>(
      this.getUser,
      { id },
      new GetTechnicianMapper()
    );
  }
}
