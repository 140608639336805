import { NgModule } from '@angular/core';
import { TechniciansComponent } from './technicians/technicians.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './auth/profile/profile.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from '../core/core.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MatSelectModule } from '@angular/material/select';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { RouterLink } from '@angular/router';
import { TranslateComponent } from '../translate/translate-component/translate.component';
import { I18NextModule } from 'angular-i18next';
import { CreateUserComponent } from './create-user/create-user.component';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LineaLatteComponent } from './linea-latte/linea-latte.component';
import { CreateLineaLatteComponent } from './dialogs/create-linea-latte/create-linea-latte.component';
import { ModifyLineaLatteComponent } from './dialogs/modify-linea-latte/modify-linea-latte.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BibComponent } from './bib/bib.component';
import { CreateBibComponent } from './dialogs/create-bib/create-bib.component';
import { ModifyBibComponent } from './dialogs/modify-bib/modify-bib.component';
import { AuthenticateComponent } from './auth/authenticate/authenticate.component';
import { NotAuthorizedComponent } from './auth/not-authorized/not-authorized.component';
import { AuthErrorComponent } from './auth/auth-error/auth-error.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    TechniciansComponent,
    ProfileComponent,
    SignInComponent,
    NavbarComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ModifyUserComponent,
    TranslateComponent,
    CreateUserComponent,
    LineaLatteComponent,
    CreateLineaLatteComponent,
    ModifyLineaLatteComponent,
    BibComponent,
    CreateBibComponent,
    ModifyBibComponent,
    AuthenticateComponent,
    NotAuthorizedComponent,
    AuthErrorComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    CoreModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatLegacyChipsModule,
    RouterLink,
    I18NextModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    BreadcrumbComponent,
    TranslateComponent,
  ],
})
export class ComponentsModule {}
