import { Component, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { NetworkService } from '../../../core/net-utils/network.service';
import { CreateBibMapper } from '../../bib/bib-mappers';
import { LogLevel } from '../../../models/log-level';

@Component({
  selector: 'app-modify-bib',
  templateUrl: './modify-bib.component.html',
  styleUrls: ['./modify-bib.component.scss'],
})
export class ModifyBibComponent {
  backendUrl = environment.api.endpointPurelatte + 'bib';
  form = new FormGroup({
    productionDate: new FormControl(new Date(), [Validators.required]),
    dailyProductionSequence: new FormControl('', [Validators.required]),
    bibCapacity: new FormControl('', [Validators.required]),
    producerCode: new FormControl('', [Validators.required]),
    milkType: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    expirationDate: new FormControl(new Date(), [Validators.required]),
    serialNumberLineaLatte: new FormControl(''),
    producerCodeLineaLatte: new FormControl(''),
  });
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<ModifyBibComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    private networkService: NetworkService
  ) {
    this.initializeVariables();
    this.form.controls.productionDate.disable();
    this.form.controls.dailyProductionSequence.disable();
    this.form.controls.expirationDate.disable();
    this.form.controls.producerCode.disable();
  }

  initializeVariables(): void {
    const bib = this.data.row;
    this.form.controls.productionDate.setValue(
      this.validDate(bib.productionDate)
    );
    this.form.controls.dailyProductionSequence.setValue(
      bib.dailyProductionSequence
    );
    this.form.controls.bibCapacity.setValue(bib.bibCapacity);
    this.form.controls.producerCode.setValue(bib.producerCode);
    this.form.controls.milkType.setValue(bib.milkType);
    this.form.controls.state.setValue(bib.state);
    this.form.controls.expirationDate.setValue(
      this.validDate(bib.expirationDate)
    );
    this.form.controls.serialNumberLineaLatte.setValue(
      bib.serialNumberLineaLatte
    );
    this.form.controls.producerCodeLineaLatte.setValue(
      bib.producerCodeLineaLatte
    );

    this.form.markAllAsTouched();
    this.form.controls.productionDate.markAsDirty();
    this.form.controls.dailyProductionSequence.markAsDirty();
    this.form.controls.bibCapacity.markAsDirty();
    this.form.controls.producerCode.markAsDirty();
    this.form.controls.milkType.markAsDirty();
    this.form.controls.state.markAsDirty();
    this.form.controls.expirationDate.markAsDirty();
    this.form.controls.serialNumberLineaLatte.markAsDirty();
    this.form.controls.producerCodeLineaLatte.markAsDirty();
  }

  validDate(s: any): Date {
    const parts = s.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  formValid(): boolean {
    return this.form.valid;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async createBib(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const mapper = new CreateBibMapper();
        mapper.productionDate =
          this.form.controls.productionDate.value ?? new Date();
        mapper.dailyProductionSequence =
          this.form.controls.dailyProductionSequence.value ?? '';
        mapper.bibCapacity = this.form.controls.bibCapacity.value ?? '';
        mapper.producerCode = this.form.controls.producerCode.value ?? '';
        mapper.milkType = this.form.controls.milkType.value ?? '';
        mapper.lastState = this.form.controls.state.value ?? '';
        mapper.expirationDate =
          this.form.controls.expirationDate.value ?? new Date();
        mapper.serialNumberLineaLatte =
          this.form.controls.serialNumberLineaLatte.value ?? '';
        mapper.producerCodeLineaLatte =
          this.form.controls.producerCodeLineaLatte.value ?? '';

        await this.networkService?.put(this.backendUrl, mapper, mapper);
        this.loading = false;
        this.data.callback();
        this.closeModal();
        this.providerService.utilService.showMessage(
          'Modified bib successfully',
          LogLevel.success
        );
      } catch (e: any) {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      }
    }
  }
}
