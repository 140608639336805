import { Injectable } from '@angular/core';
import { IUser, OAuthConfig } from './o-auth-models';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GetTokenResponseMapper } from './o-auth-mappers';
import axios from 'axios';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../../../environments/environment';
import { IMapper } from '../../../../../models/interfaces/i-mapper';
import jwt_decode from 'jwt-decode';

export const oAuthConfig: OAuthConfig = {
  // Url to redirect after login
  loginRedirectUri: window.location.origin + '/authenticating',
  // Url to redirect after logout
  logoutRedirectUri: window.location.origin + '/app/applications',
  responseType: 'code',
  clientId: environment.auth0.clientId,
  scope: 'openid offline_access email profile',
  state: '2020',
  codeChallengeMethod: 'S256',
  // Url to redirect to for login
  loginUri: `https://${environment.auth0.domain}/authorize`,
  getUserUri: `https://${environment.auth0.domain}/api/v2/users`,
  fetchTokenUri: `https://${environment.auth0.domain}/oauth/token`,
  // Url to revoke tokens
  revokeTokenUri: `https://${environment.auth0.domain}/oauth/revoke`,
  // Url to redirect to for logout
  logoutUri: `https://${environment.auth0.domain}/oidc/logout`,
};

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  isLoadingSubject$ = new BehaviorSubject<boolean>(false);
  hasError = false;
  private config = oAuthConfig;
  private queryParamsSub?: Subscription;
  private refreshingToken = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      if (params['code']) {
        this.fetchToken(params['code']);
      } else if (params['error']) {
        this.hasError = true;
        console.log('error found');
        this.isLoadingSubject$.next(true);
        const error = params['error_description'];
        this.router.navigate(['auth/not-authorized/', error]).then(() => {
          this.isLoadingSubject$.next(false);
        });
      }
    });
  }

  jwtDecode(jwt: string): any {
    return jwt_decode(jwt);
  }

  async fetchToken(code: string): Promise<void> {
    this.isLoadingSubject$.next(true);
    // eslint-disable-next-line prettier/prettier
    const body = `grant_type=authorization_code&code=${code}&redirect_uri=${this.config.loginRedirectUri}&client_id=${this.config.clientId}`;
    const responseMapper = new GetTokenResponseMapper();
    const response = await axios.post(this.config.fetchTokenUri, body);
    await this.manageResponse(response, responseMapper);
    const idTokenDecoded = this.jwtDecode(
      responseMapper.response?.idToken ?? ''
    ) as any;
    const idTokenExpires = new Date(idTokenDecoded.exp * 1000);
    this.cookieService.set(
      'MIDDLEWARE.idToken',
      responseMapper.response?.idToken ?? '',
      idTokenExpires,
      '/'
    );
    this.cookieService.set(
      'MIDDLEWARE.accessToken',
      responseMapper.response?.accessToken ?? '',
      undefined,
      '/'
    );
    this.cookieService.set(
      'MIDDLEWARE.refreshToken',
      responseMapper.response?.refreshToken ?? '',
      undefined,
      '/'
    );
    this.cookieService.set(
      'MIDDLEWARE.whenToRefresh',
      idTokenExpires.toString(),
      undefined,
      '/'
    );
    this.queryParamsSub?.unsubscribe();
    this.isLoadingSubject$.next(false);
  }

  async refreshToken(): Promise<void> {
    if (!this.cookieService.get('MIDDLEWARE.refreshToken')) {
      throw new Error('No refresh token');
    }
    // eslint-disable-next-line prettier/prettier
    const body = `grant_type=refresh_token&scope=${this.config.scope}&refresh_token=${this.cookieService.get('MIDDLEWARE.refreshToken')}&redirect_uri=${this.config.loginRedirectUri}&client_id=${this.config.clientId}`;
    const responseMapper = new GetTokenResponseMapper();
    const response = await axios.post(this.config.fetchTokenUri, body);
    await this.manageResponse(response, responseMapper);
    const idTokenDecoded = this.jwtDecode(
      responseMapper.response?.idToken ?? ''
    ) as any;
    const idTokenExpires = new Date(idTokenDecoded.exp * 1000);
    this.cookieService.set(
      'MIDDLEWARE.idToken',
      responseMapper.response?.idToken ?? '',
      idTokenExpires,
      '/'
    );
    this.cookieService.set(
      'MIDDLEWARE.accessToken',
      responseMapper.response?.accessToken ?? '',
      undefined,
      '/'
    );
    this.cookieService.set(
      'MIDDLEWARE.refreshToken',
      responseMapper.response?.refreshToken ?? '',
      undefined,
      '/'
    );
    this.cookieService.set(
      'MIDDLEWARE.whenToRefresh',
      idTokenExpires.toString(),
      undefined,
      '/'
    );
  }
  async login(): Promise<void> {
    const state = this.strRandom(40);
    this.cookieService.set('MIDDLEWARE.state', state ?? '', undefined, '/');
    this.changeHref(
      `${this.config.loginUri}?response_type=${this.config.responseType}&state=${this.config.state}&client_id=${this.config.clientId}&scope=${this.config.scope}&redirect_uri=${this.config.loginRedirectUri}`
    );
  }

  async logout(): Promise<void> {
    this.cookieService.delete('MIDDLEWARE.refreshToken', '/');
    this.cookieService.delete('MIDDLEWARE.accessToken', '/');
    this.cookieService.delete('MIDDLEWARE.idToken', '/');
    this.cookieService.delete('MIDDLEWARE.code', '/');
    this.cookieService.delete('MIDDLEWARE.state', '/');
    this.cookieService.delete('MIDDLEWARE.whenToRefresh', '/');
    this.cookieService.delete('credential-for-identity-purelatte-graphql', '/');
    this.cookieService.delete('credential-for-identity-purelatte-rest', '/');
    this.changeHref(this.config.logoutUri);
  }

  async hasValidIdToken(): Promise<boolean> {
    return !!this.cookieService.get('MIDDLEWARE.idToken');
  }

  async hasApplicationAccess(): Promise<boolean> {
    const idTokenDecoded = this.jwtDecode(await this.getIdToken()) as any;
    return !!idTokenDecoded[
      `${environment.purelatteApplication.applicationName}/role`
    ];
  }

  async getIdToken(): Promise<string> {
    if (this.refreshingToken) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          resolve(await this.getIdToken());
        }, 100);
      });
    } else {
      if (this.checkToRefresh()) {
        this.refreshingToken = true;
        await this.refreshToken();
        this.refreshingToken = false;
        if (this.cookieService.get('MIDDLEWARE.idToken')) {
          return this.cookieService.get('MIDDLEWARE.idToken');
        } else {
          throw new Error('idToken not found');
        }
      } else if (this.cookieService.get('MIDDLEWARE.idToken')) {
        return this.cookieService.get('MIDDLEWARE.idToken');
      } else {
        throw new Error('idToken not found');
      }
    }
  }

  async getUser(): Promise<IUser> {
    const token: any = jwt_decode(await this.getIdToken());
    return {
      email: token.email,
      username: token.username,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user_id: token.user_id,
      name: token.name,
      nickname: token.nickname,
    };
  }

  private checkToRefresh(): boolean {
    const whenToRefresh = this.cookieService.get('MIDDLEWARE.whenToRefresh');
    const tokenId = this.cookieService.get('MIDDLEWARE.idToken');
    return (
      (whenToRefresh && new Date(whenToRefresh) <= new Date()) ||
      tokenId.length === 0
    );
  }

  private async manageResponse(response: any, mapper: IMapper): Promise<any> {
    if (response.status === 403) {
      return Promise.reject(
        new Error(
          `Status code: ${response.statusCode} - error: ${response.body}`
        )
      );
    } else if (response.status.toString().startsWith('2')) {
      return Promise.resolve(this.convertToObject(response.data, mapper));
    } else {
      return Promise.reject(
        `Status code: ${response.status} - error: ${response.data.responseMessage}`
      );
    }
  }
  private strRandom(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  private convertToObject(responseBody: any, mapper: IMapper): IMapper {
    if (responseBody) {
      mapper.fillFromJson(responseBody);
    }
    return mapper;
  }

  private changeHref(href: string) {
    window.location.href = href;
  }
}
