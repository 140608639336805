import { CompanyModel } from '../models/company.model';
import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';
import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';

export class GetCompaniesMapper extends IGraphqlMapper {
  items: CompanyModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: CompanyModel[]; total: number } {
    this.items = [];
    const pagedCompanies = json['listCompanies'];
    pagedCompanies['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        idpId: value['idpIdentifier'],
        name: value['name'],
        businessName: value['businessName'],
        numUsers: value['numUsers'],
      });
    });
    this.total = pagedCompanies['total'];
    return { items: this.items, total: this.total };
  }
}

export class ListCompaniesMapper implements IGraphqlPagedMapper {
  items: CompanyModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: CompanyModel[]; total: number } {
    this.items = [];
    const pagedCompanies = json['listCompanies'];
    pagedCompanies['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        idpId: value['idpIdentifier'],
        name: value['name'],
        businessName: value['businessName'],
        numUsers: value['numUsers'],
      });
    });
    this.total = pagedCompanies['total'];
    return { items: this.items, total: this.total };
  }
}
