import { IMapper } from '../../models/interfaces/i-mapper';
import { BibModel } from './bib.models';
import { IExportDynamoMapper } from '../../models/interfaces/i-export-dynamo-mapper';

export class BibMapper implements IExportDynamoMapper {
  elements: BibModel[] = [];
  stringResponse = '';
  lastEvaluatedKey: any;
  total = 0;

  fillFromJson(json: any): void {
    if (typeof json === 'object') {
      this.elements = [];
      const data = json['items'];
      data.forEach((bib: any) => {
        this.elements.push({
          pk: bib['pk'],
          productionDate: bib['productionDate'],
          dailyProductionSequence: bib['dailyProductionSequence'],
          bibCapacity: bib['bibCapacity'],
          producerCode: bib['producerCode'],
          milkType: bib['milkType'],
          state: bib['lastState'],
          expirationDate: bib['expirationDate'],
          serialNumberLineaLatte: bib['serialNumberLineaLatte'],
          producerCodeLineaLatte: bib['producerCodeLineaLatte'],
        });
      });
      this.lastEvaluatedKey = json['lastEvaluatedKey'];
      this.total = json['totalCount'];
    } else if (typeof json === 'string') {
      this.stringResponse = json;
    }
  }
  fillToJson(): string {
    return JSON.stringify(this.elements);
  }
}

export class CreateBibMapper implements IMapper {
  pk?: string;
  productionDate?: Date;
  dailyProductionSequence?: string;
  bibCapacity?: string;
  producerCode?: string;
  milkType?: string;
  lastState?: string;
  expirationDate?: Date;
  serialNumberLineaLatte?: string;
  producerCodeLineaLatte?: string;

  fillFromJson(json: any): void {
    this.pk = json['pk'];
    this.productionDate = json['productionDate'];
    this.dailyProductionSequence = json['dailyProductionSequence'];
    this.bibCapacity = json['bibCapacity'];
    this.producerCode = json['producerCode'];
    this.milkType = json['milkType'];
    this.lastState = json['lastState'];
    this.expirationDate = json['expirationDate'];
    this.serialNumberLineaLatte = json['serialNumberLineaLatte'];
    this.producerCodeLineaLatte = json['producerCodeLineaLatte'];
  }

  fillToJson(): string {
    this.expirationDate?.setHours(0, 0, 0, 0);
    const formattedExpirationDate = this.expirationDate
      ? `${this.expirationDate.getDate().toString().padStart(2, '0')}/${(
          this.expirationDate.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${this.expirationDate.getFullYear()}`
      : null;
    this.productionDate?.setHours(0, 0, 0, 0);
    const formattedProductionDate = this.productionDate
      ? `${this.productionDate.getDate().toString().padStart(2, '0')}/${(
          this.productionDate.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${this.productionDate.getFullYear()}`
      : null;
    const result = {
      expirationDate: formattedExpirationDate,
      pk: this.pk,
      productionDate: formattedProductionDate,
      dailyProductionSequence: this.dailyProductionSequence,
      bibCapacity: this.bibCapacity,
      producerCode: this.producerCode,
      milkType: this.milkType,
      lastState: this.lastState,
      serialNumberLineaLatte: this.serialNumberLineaLatte,
      producerCodeLineaLatte: this.producerCodeLineaLatte,
    };
    return JSON.stringify(result);
  }
}
