import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProviderService } from '../../../core/provider.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss'],
})
export class AuthErrorComponent implements OnDestroy {
  errorMessage = '';
  private queryParamsSub: Subscription;
  constructor(
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      console.log(params);
      if (params['error']) {
        this.errorMessage = params['error'];
        this.cookieService.delete('MIDDLEWARE.refreshToken', '/');
        this.cookieService.delete('MIDDLEWARE.accessToken', '/');
        this.cookieService.delete('MIDDLEWARE.idToken', '/');
        this.cookieService.delete('MIDDLEWARE.code', '/');
        this.cookieService.delete('MIDDLEWARE.state', '/');
        this.cookieService.delete('MIDDLEWARE.whenToRefresh', '/');
      } else if (!params['error']) {
        this.providerService.utilService.navigateTo('app/applications');
      }
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSub.unsubscribe();
  }
}
