import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProviderService } from '../../core/provider.service';
import { Constants } from '../../models/constants';
import { BehaviorSubject } from 'rxjs';
import { CompanyModel } from '../../companies/models/company.model';
import { environment } from 'src/environments/environment';
import { ListCompaniesGQL } from '../../../graphql/generated';
import { ListCompaniesMapper } from '../../companies/mappers/company.mapper';

export const companyChangedSubject: BehaviorSubject<any> =
  new BehaviorSubject<any>(null);

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Output() sidebarToggleEvent = new EventEmitter<boolean>();
  user: any = { email: '', name: '' };
  eConstants = Constants;
  test = false;
  mapper = new ListCompaniesMapper();
  selectFirstOptionIfAvailable = {
    compareSelector: 'name',
    value: environment.purelatteApplication.superAdminCompanyName,
  };

  public companies: any;
  public selectedCompany?: CompanyModel;
  private environment = environment;

  constructor(
    private router: Router,
    private providerService: ProviderService,
    public listCompanies: ListCompaniesGQL
  ) {
    this.providerService.oAuthService
      .getUser()
      .then((user: any) => (this.user = user));
  }

  async ngOnInit(): Promise<void> {
    // this.getCompanies();
  }

  toggleSidebar(value: boolean): void {
    this.sidebarToggleEvent.emit(value);
  }

  signOut(): void {
    this.providerService.oAuthService.logout().then(() => {
      setTimeout(() => {
        this.navigateTo('/auth/sign-in');
      }, 200);
    });
  }

  navigateTo(href: string): void {
    this.providerService.utilService.navigateTo(href);
  }

  companySelectionChanged(event: any): void {
    this.selectedCompany = event.value;
    this.providerService.companyService.setSelectedCompany(event);
    companyChangedSubject.next(event);
    if (
      !this.providerService.companyService.getIsAdminSelected() &&
      (this.router.url.includes('bib') ||
        this.router.url.includes('lineaLatte'))
    ) {
      this.router.navigate(['/', 'app', 'users']).then();
    }
  }

  // async getCompanies(): Promise<void> {
  //   try {
  //     const userAndRole =
  //       await this.providerService.roleService.getUserAndRole();
  //     const user = userAndRole.userTypes.find(
  //       (value) =>
  //         value.applicationId === this.environment.purelatteApplication.id
  //     );
  //     if (
  //       user?.userType.name === 'technician' ||
  //       user?.userType.name === 'user'
  //     ) {
  //       this.companies =
  //         await this.providerService.companyService.getCompanies();
  //     } else if (user?.userType.name === 'operator') {
  //       // const company = await this.providerService.companyService.getCompany(
  //       //   userTypeName..companyId
  //       // );
  //       this.companies = {};
  //       if (this.companies.items.length > 0) {
  //         this.selectedCompany = this.companies[0];
  //         this.companySelectionChanged({ value: this.companies[0] });
  //       }
  //     } else {
  //       this.companies =
  //         await this.providerService.companyService.getCompanies();
  //       console.log(this.companies)
  //       if (this.companies.items.length > 0) {
  //         this.selectedCompany = this.companies.items;
  //         this.companySelectionChanged({ value: this.companies[0] });
  //       }
  //     }
  //     console.log(this.selectedCompany)
  //   } catch (error: any) {
  //     this.providerService.utilService.showMessage(
  //       error.message,
  //       LogLevel.error
  //     );
  //   }
  // }
}
