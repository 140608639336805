<div class="create-user-container">
  <div *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="create-user-form">
    <h1 class="create-user-title">{{'CREATE_USER.TITLE' | i18next}}</h1>

    <form spellcheck="false" [formGroup]="form">

      <mat-form-field appearance="fill">
        <mat-label>{{'CREATE_USER.NAME_LABEL' | i18next}}</mat-label>
        <input matInput type="text" id="name" name="name" formControlName="name" placeholder="{{'CREATE_USER.NAME_PLACEHOLDER' | i18next}}" value="">
        <mat-hint>{{'CREATE_USER.NAME_HINT' | i18next}}</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.NAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>{{'CREATE_USER.SURNAME_LABEL' | i18next}}</mat-label>
        <input matInput type="text" id="surname" name="surname" formControlName="surname" placeholder="{{'CREATE_USER.SURNAME_PLACEHOLDER' | i18next}}" value="">
        <mat-hint>{{'CREATE_USER.SURNAME_HINT' | i18next}}</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'surname'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.SURNAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>{{'CREATE_USER.EMAIL_LABEL' | i18next}}</mat-label>
        <input matInput type="mail" id="email" name="email" formControlName="email" placeholder="{{'CREATE_USER.EMAIL_PLACEHOLDER' | i18next}}" value="">
        <i class="fa fa-regular fa-envelope" matSuffix></i>
        <mat-hint>{{'CREATE_USER.EMAIL_HINT' | i18next}}</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'email'" [errorCode]="'pattern'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.EMAIL_NOT_VALID_ERROR' | i18next}}"></app-validator-ui>
      <app-validator-ui [form]="form" [name]="'email'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.EMAIL_REQUIRED_ERROR' | i18next}}"></app-validator-ui>
    </form>
  </div>
  <div class="create-user-options-body">
    <h2 class="create-user-options-title">{{'CREATE_USER.COMPANIES_AREAS_TITLE' | i18next}}</h2>
    <app-select-infinite-scroll-multi
      [icon] = "'fa-solid fa-buildings'"
      label = "{{'CREATE_USER.COMPANIES_LABEL' | i18next}}"
      [dataShowSelectors] = "['name']"
      [query] = "listCompaniesGQL"
      [mapper] = "listCompaniesMapper"
      [params] = "{}"
      [selectedData]="selectedCompanies"
      [disabledChanged] = disabledChanged
      (selectionChanged)="companySelectionChanged($event)">
    </app-select-infinite-scroll-multi>

    <div class="options-container-header" *ngIf="selectedCompanies.length > 0">
      <div class="option-row title">
        <div class="options-name">
          <span>Company:</span>
        </div>
        <div class="option-value">
          <span>Areas:</span>
        </div>
      </div>
    </div>
    <div *ngFor="let company of selectedCompanies" class="applications-container">
      <div class="option-row">
        <div class="options-name">
          <span>{{company.name}}:</span>
        </div>
        <div class="option-value">
          <app-select-infinite-scroll-multi
            [icon] = "'fa-solid fa-location-dot'"
            label = "{{'CREATE_USER.AREAS_LABEL' | i18next}}"
            [dataShowSelectors] = "['region', 'city', 'location']"
            [query] = "listAreas"
            [mapper] = "listAreasMapper"
            [params] = "{companyId: company.id}"
            [noneOption] = true
            (selectionChanged)="areasSelectionChanged(company, $event)">
          </app-select-infinite-scroll-multi>
        </div>
      </div>
    </div>
  </div>
  <div class="create-user-options-body">
    <div class="options-container-header" *ngIf="selectedApplications.length > 0">
      <div class="option-row title">
        <div class="options-name">
          <span>Application:</span>
        </div>
        <div class="option-value">
          <span>User type:</span>
        </div>
      </div>
    </div>
    <div *ngFor="let application of selectedApplications" class="applications-container">
      <div class="option-row">
        <div class="options-name">
          <span>{{application.applicationName}}:</span>
        </div>
        <div class="option-value">
          <mat-form-field appearance="fill">
            <mat-label>{{'CREATE_USER.USER_ROLE_LABEL' | i18next}}</mat-label>
            <mat-select (selectionChange)="userRoleSelectionChanged(application, $event)">
              <mat-option *ngFor="let userType of userTypes" [value]="userType">{{providerService.utilService.toCapitalizedWords(userType.name)}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="create-user-button">
    <button mat-raised-button color="primary" (click)="navigateToOrigin()">
      <span>{{'CREATE_USER.BACK' | i18next}}</span>
    </button>
    <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="addUser();" [disabled]="!formValid() || loading">{{'CREATE_USER.CREATE_USER' | i18next}}</button>
  </div>

</div>
