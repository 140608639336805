import { Component, OnDestroy } from '@angular/core';
import { TableDataSource } from '../../core/form-utils/table/table.datasource';
import {
  DeleteLinearLatteMapper,
  ListLineaLatteMapper,
} from './linea-latte-mappers';
import { LineaLatteModel } from './linea-latte.models';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  skip,
  Subscription,
} from 'rxjs';
import { ProviderService } from '../../core/provider.service';
import { LogLevel } from '../../models/log-level';
import { CreateLineaLatteComponent } from '../dialogs/create-linea-latte/create-linea-latte.component';
import { environment } from '../../../environments/environment';
import { ModifyLineaLatteComponent } from '../dialogs/modify-linea-latte/modify-linea-latte.component';
import { NetworkService } from '../../core/net-utils/network.service';

@Component({
  selector: 'app-linea-latte',
  templateUrl: './linea-latte.component.html',
  styleUrls: ['./linea-latte.component.scss'],
})
export class LineaLatteComponent implements OnDestroy {
  backendUrl = environment.api.endpointPurelatte + 'linea-latte';

  dataSource: TableDataSource<LineaLatteModel> =
    new TableDataSource<LineaLatteModel>();

  lineaLatteMapper: ListLineaLatteMapper = new ListLineaLatteMapper();

  tableDefinitions = [
    {
      def: 'serialNumber',
      title: _('LINEA_LATTE.SERIAL_NUMBER'),
      sortable: false,
    },
    {
      def: 'expirationDate',
      title: _('LINEA_LATTE.EXPIRATION_DATE'),
      sortable: false,
    },
    {
      def: 'producerCode',
      title: _('LINEA_LATTE.PRODUCER_CODE'),
      sortable: false,
    },
    { def: 'state', title: _('LINEA_LATTE.LAST_STATE'), sortable: true },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: _('LINEA_LATTE.MODIFY_LINEA_LATTE'),
      styleClass: 'table-action',
      callback: (row: any): void => {
        this.modifyLineaLatte(row);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: _('LINEA_LATTE.DELETE_LINEA_LATTE'),
      styleClass: 'table-action-delete',
      callback: (row: any): void => {
        this.deleteLineaLatte(row);
      },
    },
  ];

  filterObservable = new BehaviorSubject<string>('');

  debounceSubject = new BehaviorSubject<string>('');
  lastDebounceValue = '';
  filterSub?: Subscription;
  lastState: any;

  constructor(
    private providerService: ProviderService,
    private networkService: NetworkService
  ) {
    this.filterSub = this.debounceSubject
      .pipe(skip(1), debounceTime(500), distinctUntilChanged())
      .subscribe((value: any) => {
        this.filterObservable.next(value);
      });
  }

  sendFilterQuery(event: any): void {
    this.lastDebounceValue = event;
    this.debounceSubject.next(event);
  }

  ngOnDestroy(): void {
    this.filterSub?.unsubscribe();
  }

  addLineaLatteCallback(): void {
    this.filterObservable.next(this.lastDebounceValue);
  }

  addLineaLatte(): void {
    this.providerService.utilService.openDialog(
      CreateLineaLatteComponent,
      {
        callback: () => {
          this.addLineaLatteCallback();
        },
      },
      'create-modal'
    );
  }

  async modifyLineaLatte(row: any): Promise<void> {
    this.providerService.utilService.openDialog(
      ModifyLineaLatteComponent,
      {
        row,
        callback: () => {
          this.addLineaLatteCallback();
        },
      },
      'create-modal'
    );
  }

  async deleteLineaLatte(row: any): Promise<void> {
    const responseMapper = new DeleteLinearLatteMapper();
    try {
      await this.networkService?.delete(
        this.backendUrl + '/' + row.pk.replace(/#/g, '_').replace(/\//g, '-'),
        responseMapper
      );
      this.filterObservable.next(this.lastDebounceValue);
      this.providerService.utilService.showMessage(
        'Deleted LL successfully',
        LogLevel.success
      );
    } catch (error: any) {
      this.providerService.utilService.showMessage(
        error.toString(),
        LogLevel.error
      );
    }
  }
}
