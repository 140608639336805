import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutInAppComponent } from './layout/layout-in-app/layout-in-app.component';
import { TechniciansComponent } from './components/technicians/technicians.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { LayoutOutAppComponent } from './layout/layout-out-app/layout-out-app.component';
import { RoleGuardService } from './core/auth-utils/role-guard.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { ModifyUserComponent } from './components/modify-user/modify-user.component';
import { LineaLatteComponent } from './components/linea-latte/linea-latte.component';
import { BibComponent } from './components/bib/bib.component';
import { AuthenticateComponent } from './components/auth/authenticate/authenticate.component';
import { AuthGuardService } from './core/auth-utils/auth-guard.service';
import { NotAuthorizedComponent } from './components/auth/not-authorized/not-authorized.component';
import { AuthErrorComponent } from './components/auth/auth-error/auth-error.component';

const routes: Routes = [
  {
    path: _('auth'),
    component: LayoutOutAppComponent,
    children: [
      { path: 'sign-in', component: SignInComponent },
      { path: 'not-authorized/:error', component: AuthErrorComponent },
    ],
  },
  {
    path: 'authenticating',
    component: AuthenticateComponent,
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: _('app'),
    component: LayoutInAppComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: _('users'),
        component: TechniciansComponent,
        canActivate: [RoleGuardService],
      },
      {
        path: _('users/add-user'),
        component: CreateUserComponent,
        canActivate: [RoleGuardService],
      },
      {
        path: _('users/modify-user'),
        component: ModifyUserComponent,
        canActivate: [RoleGuardService],
      },
      {
        path: _('lineaLatte'),
        component: LineaLatteComponent,
        canActivate: [RoleGuardService],
      },
      {
        path: _('bib'),
        component: BibComponent,
        canActivate: [RoleGuardService],
      },
      { path: _('profile'), component: ProfileComponent },
    ],
  },
  { path: '', redirectTo: '/app/users', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
