import { Component, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { NetworkService } from '../../../core/net-utils/network.service';
import { IMapper } from '../../../models/interfaces/i-mapper';
import { LogLevel } from '../../../models/log-level';
import { CreateBibMapper } from '../../bib/bib-mappers';

@Component({
  selector: 'app-create-bib',
  templateUrl: './create-bib.component.html',
  styleUrls: ['./create-bib.component.scss'],
})
export class CreateBibComponent {
  backendUrl = environment.api.endpointPurelatte + 'bib';
  form = new FormGroup({
    productionDate: new FormControl(new Date(), [Validators.required]),
    dailyProductionSequence: new FormControl('', [Validators.required]),
    bibCapacity: new FormControl('', [Validators.required]),
    producerCode: new FormControl('', [Validators.required]),
    milkType: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    expirationDate: new FormControl(new Date(), [Validators.required]),
    serialNumberLineaLatte: new FormControl(''),
    producerCodeLineaLatte: new FormControl(''),
  });
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<CreateBibComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    private networkService: NetworkService
  ) {}

  formValid(): boolean {
    return this.form.valid;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async createBib(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const mapper = new CreateBibMapper();
        mapper.productionDate =
          this.form.controls.productionDate.value ?? new Date();
        mapper.dailyProductionSequence =
          this.form.controls.dailyProductionSequence.value ?? '';
        mapper.bibCapacity = this.form.controls.bibCapacity.value ?? '';
        mapper.producerCode = this.form.controls.producerCode.value ?? '';
        mapper.milkType = this.form.controls.milkType.value ?? '';
        mapper.lastState = this.form.controls.state.value ?? '';
        mapper.expirationDate =
          this.form.controls.expirationDate.value ?? new Date();
        mapper.serialNumberLineaLatte =
          this.form.controls.serialNumberLineaLatte.value ?? '';
        mapper.producerCodeLineaLatte =
          this.form.controls.producerCodeLineaLatte.value ?? '';

        await this.networkService?.put(
          this.backendUrl,
          mapper,
          mapper
        );
        this.loading = false;
        this.data.callback();
        this.closeModal();
        this.providerService.utilService.showMessage(
          'BIB created successfully!',
          LogLevel.success
        );
      } catch (e: any) {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      }
    }
  }
}
