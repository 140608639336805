// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const environment = {
  auth0: {
    domain: 'dev-7b2tz6otxy08pmtk.us.auth0.com', // 'dev-zgsrvymivlynwajo.us.auth0.com',
    clientId: 'G89PDXxpEOcktWuvqNmACfosCmAeEa68', // 'x2xiWJrMWh1TWK99x7RDXdBPLZu233Hh',
    redirectUri: `${window.location.origin}/app/users`,
  },
  cognito: {
    identityPoolId: 'eu-west-1:9926395e-10df-4bcb-8879-cd413f296e79',
    identityPoolIdPureLatte: 'eu-west-1:2ddd0673-d025-4b51-8c3e-72e5b50b77ac',
  },
  api: {
    endpointMiddleware:
      'https://qtvtqs5pdl.execute-api.eu-west-1.amazonaws.com/prod/v1',
    endpointPurelatte: 'https://api.dev.purelatte.evoca.cloud/v1/',
  },
  purelatteApplication: {
    superAdminCompanyName: 'Superadmin Evoca',
    applicationName: 'purelatte',
    id: '2b5d90ca-e89b-4199-80c9-4dc4ebb614e2',
    mobileId: '60d33244-499a-405e-b51f-41fdbe6c1bd1',
  },
  graphql: {
    endpoint:
      'https://5sood4yuafhlhg4p7vxqrdsy4i.appsync-api.eu-west-1.amazonaws.com/graphql',
  },
};

export { environment };
