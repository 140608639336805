import { Component, OnInit } from '@angular/core';
import { ProviderService } from '../../../core/provider.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user: any = { email: '', name: '' };

  constructor(private providerService: ProviderService) {
    this.providerService.oAuthService
      .getUser()
      .then((user: any) => (this.user = user));
  }

  async ngOnInit(): Promise<void> {}
}
