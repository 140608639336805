import { Injectable } from '@angular/core';
import { Constants } from '../../models/constants';
import { CompanyModel } from '../models/company.model';
import { GetCompaniesMapper } from '../mappers/company.mapper';
import { ListCompaniesGQL } from '../../../graphql/generated';
import { GraphqlService } from '../../core/app-utils/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private selectedCompany?: CompanyModel;
  private fromCompanyDetails = false;

  constructor(
    private listCompanies: ListCompaniesGQL,
    private graphqlService: GraphqlService
  ) {}

  getSelectedCompany(): CompanyModel | undefined {
    return this.selectedCompany;
  }
  setSelectedCompany(company: CompanyModel): void {
    this.selectedCompany = company;
  }
  getIsAdminSelected(): boolean {
    if (this.selectedCompany && this.selectedCompany.name) {
      return this.selectedCompany.name === Constants.superAdmin;
    } else {
      return false;
    }
  }

  async getCompanies(): Promise<CompanyModel[]> {
    const mapper = new GetCompaniesMapper();
    return await this.graphqlService.fetch<any>(this.listCompanies, {}, mapper);
  }
  getFromCompanyDetails(): boolean {
    return this.fromCompanyDetails;
  }

  setFromCompanyDetails(fromCompanyDetails: boolean): void {
    this.fromCompanyDetails = fromCompanyDetails;
  }
}
