<br><br>

<div class="align-right">
  <button mat-raised-button color="primary" (click)="addLineaLatte();">
    <i class="fa-solid fa-cow"></i>
    <span>{{'LINEA_LATTE.ADD_LINEA_LATTE' | i18next}}</span>
  </button>
</div>

<div class="table-header-container">
  <div class="table-header-text">
    <i class="fa-solid fa-cow"></i>
    <h1>{{'LINEA_LATTE.LINEA_LATTE_TITLE' | i18next}}</h1>
  </div>

  <div class="table-search-form-container">
    <mat-form-field appearance="fill">
      <mat-select id="lastState" placeholder="{{'CREATE_LINEA_LATTE.LAST_STATE' | i18next}}" [(ngModel)]="lastState" (ngModelChange)="sendFilterQuery($event)" >
        <mat-option value="NOT_USED">
          {{'CREATE_LINEA_LATTE.NOT_USED' | i18next}}
        </mat-option>
        <mat-option value="USED">
          {{'CREATE_LINEA_LATTE.USED' | i18next}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<app-table-ui
  *ngIf="dataSource"
  [datasource]="dataSource"
  [tableDefinitions]="tableDefinitions"
  [actionDefinitions]="actionDefinitions"
  [mapper]="lineaLatteMapper"
  [backendUrl]="backendUrl"
  [pageSize]="10"
  [pageSizeOptions]="[10,20,30]"
  [filterObservable]="filterObservable"
  [hasRowAction]="true">
</app-table-ui>
