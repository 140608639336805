import { IMapper } from 'src/app/models/interfaces/i-mapper';
import { FetchTokenResponse } from './o-auth-models';

export class GetTokenResponseMapper implements IMapper {
  response?: FetchTokenResponse;
  fillFromJson(json: any): void {
    this.response = {
      accessToken: json['access_token'],
      refreshToken: json['refresh_token'],
      scope: json['scope'],
      idToken: json['id_token'],
      tokenType: json['token_type'],
      expiresIn: json['expires_in'],
    };
  }

  fillToJson(): string {
    return JSON.stringify(this.response);
  }
}

export class GetTokenBodyMapper implements IMapper {
  grantType?: string;
  code?: string;
  // redirectUri?: string;
  clientId?: string;
  clientSecret?: string;
  fillFromJson(json: any): void {
    this.grantType = json['grant_type'];
    this.code = json['code'];
    // this.redirectUri = json['redirect_uri'];
    this.clientId = json['client_id'];
    this.clientSecret = json['client_secret'];
  }

  fillToJson(): string {
    return JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      grant_type: this.grantType,
      code: this.code,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // redirect_uri: this.redirectUri,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: this.clientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_secret: this.clientSecret,
    });
  }
}
