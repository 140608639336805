import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Apollo from 'apollo-angular';
import { OrderType } from '../../../../graphql/generated';
import { ProviderService } from '../../provider.service';
import { IGraphqlMapper } from 'src/app/models/interfaces/I-graphql-mapper';
import { Model } from '../../../../../../shared/core/model';

export class GraphqlTableDatasource<M extends Model> implements DataSource<M> {
  public loading$: Observable<boolean>;

  private tableSubject = new BehaviorSubject<M[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCount = 0;
  private localParams?: { [key: string]: string };

  constructor(
    private providerService: ProviderService,
    private query: Apollo.Query<any, any>,
    private mapper: IGraphqlMapper,
    private params?: { [key: string]: string }
  ) {
    this.loading$ = this.loadingSubject.asObservable();
    this.localParams = params;
  }

  changeVariables(params?: { [key: string]: string }): void {
    this.localParams = params;
    this.loadElements();
  }

  getTotalCount(): number {
    return this.totalCount;
  }

  connect(_: CollectionViewer): Observable<M[]> {
    return this.tableSubject.asObservable();
  }

  disconnect(_: CollectionViewer): void {
    this.tableSubject.complete();
    this.loadingSubject.complete();
  }

  async loadElements(
    filter = '',
    sortItem = '',
    sortDirection: OrderType = OrderType.Asc,
    pageIndex = 0,
    pageSize = 10
  ): Promise<void> {
    this.loadingSubject.next(true);
    const variables: {
      [key: string]: any;
    } = {};
    variables['page'] = {
      filter,
      limit: pageSize,
      orderCol: this.providerService.utilService.camelToSnakeCase(sortItem),
      begins: pageIndex * pageSize,
      order: sortDirection,
    };
    for (const key in this.localParams) {
      if (Object.hasOwn(this.localParams, key)) {
        variables[key] = this.localParams[key];
      }
    }
    const result = await this.providerService.graphqlService.fetch<any>(
      this.query,
      variables,
      this.mapper
    );
    this.totalCount = result.total;
    this.tableSubject.next(result.items);
    this.loadingSubject.next(false);
  }
}
