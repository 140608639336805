import { environment } from '../../environments/environment';

export class Constants {
  static title = 'Purelatte';
  static snackbarDuration = 3000;
  static superAdmin = 'Superadmin Evoca';

  static apiEndpoint = environment.api.endpointMiddleware;
  static verifyLineaLatteApiPath = `linea-latte/verify`;
  static defaultLanguage = 'en';
}
