import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogLevel } from '../../models/log-level';
import { Constants } from '../../models/constants';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  dialogRef: any = null;

  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {}

  showMessage(message: string, type: LogLevel): void {
    this.snackbar.open(message, 'Dismiss', {
      duration: Constants.snackbarDuration,
      panelClass: `app-notification-${type}`,
    });
  }

  openDialog(component: any, data: any, panelClass?: string): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.dialog.open(component, {
      data,
      panelClass,
    });
  }

  async navigateTo(href: string): Promise<void> {
    await this.router.navigate(href.split('/'));
  }

  camelToSnakeCase(value: string): string {
    return value.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }

  toCapitalizedWords(word: string): string {
    const capitalize = (w: string) =>
      w.charAt(0).toUpperCase() + w.substring(1);
    const words = word.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(capitalize).join(' ');
  }
}
