<div class="table-ui-container">
  <div *ngIf="(datasource!.loading$ | async) || !backendUrl">
    <mat-spinner style="z-index: 99"></mat-spinner>
  </div>

  <div class="table-container">

    <mat-table matSort [dataSource]="datasource!">

      <ng-container *ngFor="let item of tableDefinitions">

        <ng-container matColumnDef="{{item.def}}">

          <ng-container *ngIf="item.sortable">
            <mat-header-cell mat-sort-header *matHeaderCellDef>{{item.title | i18next}}</mat-header-cell>
          </ng-container>

          <ng-container *ngIf="!item.sortable">
            <mat-header-cell *matHeaderCellDef>{{item.title | i18next}}</mat-header-cell>
          </ng-container>

          <mat-cell *matCellDef="let cell">{{item.prefix ?? '' | i18next}}{{cell[item.def]}}{{item.suffix ?? '' | i18next}}</mat-cell>

        </ng-container>

      </ng-container>

      <ng-container *ngIf="actionDefinitions && actionDefinitions.length > 0" matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{actionTitle | i18next}}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
          <div class="action-menu" (click)="$event.stopPropagation()" [matMenuTriggerFor]="actionsMenu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </div>
          <mat-menu #actionsMenu="matMenu" class="table-action-menu">
            <a mat-menu-item *ngFor="let action of actionDefinitions" (click)="action.callback(row)" matTooltip="{{action?.tooltip ?? '' | i18next}}">
              <i class="{{action.icon}} {{action.styleClass}}"></i>
              &nbsp;
              <span class="{{action.styleClass}}">{{action.text | i18next}}</span>
            </a>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="tableDefinitionHeaders"></mat-header-row>

      <mat-row *matRowDef="let row; columns: tableDefinitionHeaders" (click)="rowClickedAction(row);" class="{{hasRowAction ? 'table-row-action': 'table-row-no-action'}}"></mat-row>

    </mat-table>

    <mat-paginator [length]="tableService.getTotalCount()" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>
</div>
