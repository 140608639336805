import { Component } from '@angular/core';
import { ProviderService } from '../../../core/provider.service';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss'],
})
export class AuthenticateComponent {
  constructor(private providerService: ProviderService) {
    this.providerService.oAuthService.isLoadingSubject$.subscribe((value) => {
      if (!value) {
        this.providerService.utilService.navigateTo('app/users');
      }
    });
  }
}
