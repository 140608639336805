<br><br>
 <div class="align-right">
    <button mat-raised-button color="primary" (click)="addBib();">
      <i class="fa-solid fa-box"></i>
      <span>{{'BIB.ADD_BIB' | i18next}}</span>
    </button>
  </div>

  <div class="table-header-container">
    <div class="table-header-text">
      <i class="fa-solid fa-box"></i>
      <h1>{{'BIB.BIB_TITLE' | i18next}}</h1>
    </div>

    <div class="table-search-form-container">
      <mat-form-field appearance="fill">
        <mat-label>{{'BIB.SEARCH_LABEL' | i18next}}</mat-label>
          <mat-select id="lastState" placeholder="{{'MODIFY_BIB.LAST_STATE' | i18next}}" [(ngModel)]="lastState" (ngModelChange)="sendFilterQuery($event)" >
              <mat-option value="NOT_USED">
                  {{'MODIFY_BIB.NOT_USED' | i18next}}
              </mat-option>
              <mat-option value="USED">
                  {{'MODIFY_BIB.USED' | i18next}}
              </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
  </div>
<app-table-ui
  *ngIf="dataSource"
  [datasource]="dataSource"
  [tableDefinitions]="tableDefinitions"
  [actionDefinitions]="actionDefinitions"
  [mapper]="bibMapper"
  [backendUrl]="backendUrl"
  [pageSize]="10"
  [pageSizeOptions]="[10,20,30]"
  [filterObservable]="filterObservable"
  [hasRowAction]="true">
</app-table-ui>
