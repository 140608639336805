import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  UserModel,
  UserTypeModel,
} from '../../technicians/models/technician.model';
import {
  GetApplicationReducedMapper,
  ListCompaniesByUserIdMapper,
  ModifyUserMapper,
} from './modify-user.mapper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AreasCompanyModel } from '../../companies/models/company.model';
import { ProviderService } from '../../core/provider.service';
import {
  GetApplicationGQL,
  GetUserGQL,
  ListApplicationsGQL,
  ListAreasGQL,
  ListCompaniesGQL,
  ListUserTypesGQL,
  UpdateUserGQL,
  UpdateUserMutationVariables,
} from '../../../graphql/generated';
import { ListUserTypesMapper } from '../create-user/create-user.mappers';
import { AreasMapper } from '../create-user/mappers/areas.mapper';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { LogLevel } from '../../models/log-level';
import { ListApplicationsMapper } from '../create-user/mappers/applications.mappers';
import { ApplicationModel } from '../../../../../shared/models/applications-models';
import {
  AreaModel,
  ModifyUserAreaModel,
} from '../../../../../shared/models/area.model';
import { GetUserMapper } from '../../mappers/user.mapper';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.scss'],
})
export class ModifyUserComponent implements OnInit {
  result: any = {};
  loading = false;
  userTypes: UserTypeModel[] = [];
  companies: { idCompany: string; nameCompany: string }[] = [];
  listCompaniesMapper = new ListCompaniesByUserIdMapper();
  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
  });
  companySelectionDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  applicationSelectionDisabled: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  areaSelectionDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  userRoleSelectionDisabled = true;
  selectedCompanies: AreasCompanyModel[] = [];
  initiating = false;
  startingUser?: UserModel;
  listApplicationsMapper: ListApplicationsMapper = new ListApplicationsMapper();
  listAreasMapper: AreasMapper = new AreasMapper();
  selectedApplications: ApplicationModel[] = [];
  selectedAreas: { [companyId: string]: ModifyUserAreaModel[] } = {};
  selectedUserRoles: { [applicationId: string]: UserTypeModel } = {};
  currentUser?: UserModel;
  companyHasNoneOption: { [companyId: string]: boolean } = {};
  inEditingMode = false;
  constructor(
    public providerService: ProviderService,
    private modifyUserQuery: UpdateUserGQL,
    public listUserTypes: ListUserTypesGQL,
    public listCompaniesGQL: ListCompaniesGQL,
    public listApplications: ListApplicationsGQL,
    public listAreas: ListAreasGQL,
    private getApplication: GetApplicationGQL,
    private getUser: GetUserGQL
  ) {}

  ngOnInit(): void {
    try {
      this.startingUser =
        this.providerService.technicianService.getSelectedTechnician();
      this.disableAllOptions();
    } catch (e) {
      this.providerService.utilService.showMessage(
        _('MODIFY_USER.GET_SELECTED_USER_ERROR'),
        LogLevel.error
      );
      this.navigateToOrigin();
    }
  }

  async getUserTypes(): Promise<void> {
    this.userTypes = await this.providerService.graphqlService.fetch(
      this.listUserTypes,
      {},
      new ListUserTypesMapper()
    );
  }

  formValid(): boolean {
    let areasSelected = true;
    if (Object.keys(this.selectedAreas).length > 0) {
      for (const key of this.selectedCompanies) {
        if (this.selectedAreas[key.id]) {
          if (this.selectedAreas[key.id].length === 0) {
            areasSelected = false;
          }
        }
      }
    } else {
      areasSelected = false;
    }
    let rolesSelected = true;
    if (Object.keys(this.selectedUserRoles).length > 0) {
      for (const key of this.selectedApplications) {
        if (!this.selectedUserRoles[key.applicationId]) {
          rolesSelected = false;
        }
      }
    } else {
      rolesSelected = false;
    }
    return this.form.valid && rolesSelected && areasSelected;
  }

  async modifyUser(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const selectedUserRoles: {
          applicationId: string;
          userTypeId: string;
        }[] = [];
        for (const userType of this.startingUser?.userTypes ?? []) {
          if (
            userType &&
            userType.applicationId !== environment.purelatteApplication.id &&
            userType.applicationId !== environment.purelatteApplication.mobileId
          ) {
            selectedUserRoles.push({
              applicationId: userType.applicationId,
              userTypeId: userType.userType.id,
            });
          }
        }
        for (const key of this.selectedApplications) {
          if (key) {
            selectedUserRoles.push({
              applicationId: key.applicationId,
              userTypeId: this.selectedUserRoles[key.applicationId].id,
            });
          }
        }
        const selectedCompany: { companyId: string; areaIds: string[] }[] = [];
        for (const key of this.selectedCompanies) {
          if (key) {
            const areas = this.selectedAreas[key.id].map((value) => value.id);
            if (areas.length > 0 && areas[0] === 'none') {
              selectedCompany.push({
                companyId: key.id,
                areaIds: [],
              });
            } else {
              selectedCompany.push({
                companyId: key.id,
                areaIds: this.selectedAreas[key.id].map((value) => value.id),
              });
            }
          }
        }
        const variables = {
          id: this.startingUser?.userId,
          email: this.startingUser?.email,
          name: this.form.controls.name.value ?? this.startingUser?.name,
          surname:
            this.form.controls.surname.value ?? this.startingUser?.surname,
          userTypes: selectedUserRoles,
          companies: selectedCompany,
        } as UpdateUserMutationVariables;
        await this.providerService.graphqlService.mutate(
          this.modifyUserQuery,
          variables,
          new ModifyUserMapper()
        );
        this.providerService.utilService.showMessage(
          _('MODIFY_USER.USER_MODIFIED_CORRECTLY'),
          LogLevel.success
        );
        this.navigateToOrigin();
      } catch (error: any) {
        this.providerService.utilService.showMessage(
          error.toString(),
          LogLevel.error
        );
      } finally {
        this.loading = false;
      }
    }
  }

  companySelectionChanged($event: any): void {
    for (const company of $event) {
      if (this.providerService.companyService.getIsAdminSelected()) {
        this.companyHasNoneOption[company.id] = true;
      } else {
        this.companyHasNoneOption[company.id] = this.getHasNoneOption(company);
      }
    }
    this.selectedCompanies = $event;
  }

  navigateToOrigin(): void {
    this.providerService.utilService.navigateTo('app/users');
  }

  applicationsSelectionChanged($event: ApplicationModel[]): void {
    $event.forEach((application) => {
      if (
        this.selectedApplications.find(
          (value: ApplicationModel) =>
            value.applicationId === application.applicationId
        ) === undefined
      ) {
        this.selectedUserRoles[application.applicationId] = { id: '' } as any;
      }
    });
    this.selectedApplications = $event;
  }

  async initializeVariables(user: UserModel): Promise<void> {
    this.initiating = true;
    await this.getUserTypes();
    this.currentUser =
      await this.providerService.graphqlService.fetch<UserModel>(
        this.getUser,
        {},
        new GetUserMapper()
      );
    for (const company of user.companies) {
      if (this.providerService.companyService.getIsAdminSelected()) {
        this.companyHasNoneOption[company.id] = true;
      } else {
        this.companyHasNoneOption[company.id] = this.getHasNoneOption(company);
      }
    }
    this.selectedCompanies = [];
    this.selectedAreas = {};
    this.selectedCompanies = user.companies;
    for (const value of user.companies) {
      if (value.areas && value.areas.length > 0) {
        this.selectedAreas[value.id] = value.areas;
      } else {
        this.selectedAreas[value.id] = [
          {
            id: 'none',
            region: '',
            city: '',
            location: '',
          },
        ];
      }
    }
    this.selectedApplications = [];
    this.selectedUserRoles = {};
    for (const value of user.userTypes) {
      if (
        value.applicationId === environment.purelatteApplication.id ||
        value.applicationId === environment.purelatteApplication.mobileId
      ) {
        this.selectedApplications.push(
          await this.providerService.graphqlService.fetch<ApplicationModel>(
            this.getApplication,
            { id: value.applicationId },
            new GetApplicationReducedMapper()
          )
        );
      }
      this.selectedUserRoles[value.applicationId] = value.userType;
    }
    this.form.controls.name.setValue(user.name);
    this.form.controls.surname.setValue(user.surname);
    this.form.markAllAsTouched();
    this.markFormAsDirty();
    this.initiating = false;
  }

  async disableAllOptions(): Promise<void> {
    this.inEditingMode = false;
    if (this.startingUser) {
      await this.initializeVariables(this.startingUser);
    }
    this.form.controls.name.disable();
    this.form.controls.surname.disable();
    this.applicationSelectionDisabled.emit(true);
    this.areaSelectionDisabled.emit(true);
    this.companySelectionDisabled.emit(true);
    this.userRoleSelectionDisabled = true;
    this.form.controls.name.markAsPristine();
    this.form.controls.surname.markAsPristine();
  }

  enableAllOptions(): void {
    this.inEditingMode = true;
    // this.applicationSelectionDisabled.emit(false);
    this.companySelectionDisabled.emit(false);
    this.form.controls.name.enable();
    this.form.controls.surname.enable();
    this.areaSelectionDisabled.emit(false);
    this.userRoleSelectionDisabled = false;
  }

  areasSelectionChanged(company: AreasCompanyModel, $event: AreaModel[]): void {
    if ($event.length > 0 && $event[0].id === 'none') {
      this.selectedAreas[company.id] = [
        {
          id: 'none',
          region: '',
          city: '',
          location: '',
        },
      ];
    } else {
      this.selectedAreas[company.id] = $event;
    }
  }

  userRoleSelectionChanged(application: ApplicationModel, $event: any): void {
    this.selectedUserRoles[application.applicationId] = $event.value;
  }

  getHasNoneOption(company: AreasCompanyModel): boolean {
    console.log(this.currentUser);
    if (
      this.currentUser &&
      this.currentUser.companies &&
      this.currentUser.companies.length > 0
    ) {
      const areas = this.currentUser.companies.find(
        (value) => (value.id = company.id)
      )?.areas;
      console.log(areas);
      if ((areas && areas.length === 0) || !areas) {
        return true;
      }
    }
    return false;
  }

  private markFormAsDirty(): void {
    this.form.controls.name.markAsDirty();
    this.form.controls.surname.markAsDirty();
  }
}
