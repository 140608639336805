<div class="table-ui-container">
  <div *ngIf="datasource!.loading$ | async">
    <mat-spinner></mat-spinner>
  </div>

  <div class="table-container">

    <mat-table matSort [dataSource]="datasource!">

      <ng-container *ngFor="let item of tableDefinitions">

        <ng-container matColumnDef="{{item.def}}">

          <ng-container *ngIf="item.sortable">
            <mat-header-cell mat-sort-header *matHeaderCellDef>{{item.title | i18next}}</mat-header-cell>
          </ng-container>

          <ng-container *ngIf="!item.sortable">
            <mat-header-cell *matHeaderCellDef>{{item.title | i18next}}</mat-header-cell>
          </ng-container>

          <mat-cell *matCellDef="let cell">
            <ng-container *ngIf="!item.iconItem">
              <ng-container *ngIf="!item.secondDef">
                {{item.prefix}}{{cell[item.def]}}{{item.suffix}}
              </ng-container>
              <ng-container *ngIf="item.secondDef && cell[item.def] && cell[item.def][item.secondDef]">
                {{item.prefix}}{{cell[item.def][item.secondDef]}}{{item.suffix}}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="item.iconItem">
              <div *ngIf="cell[item.def].length !== 0">
                <div matTooltip="{{mapIconItem(cell[item.def], item.iconItemName!)}}">
                  <ng-template ngFor let-icon [ngForOf]="cell[item.def]">
                    <div class="iconItem" style="background-color: #{{icon[item.iconItemColorName!].trim().replace('#', '') || 'DF002C'}}">
                      {{icon[item.iconItemName!].slice(0,2)}}
                    </div>
                  </ng-template>
                  <span class="iconItemOtherText" *ngIf="(cell[item.def].length || 0) > 3">
                    +{{(cell[item.def].length || 0) - 3}} {{cell[item.def].length > 4 ? 'others' : 'other'}}
                  </span>
                </div>
              </div>
            </ng-container>
          </mat-cell>

        </ng-container>

      </ng-container>

      <ng-container *ngIf="actionDefinitions && actionDefinitions.length > 0" matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{actionTitle | i18next}}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
          <div class="action-menu" (click)="$event.stopPropagation()" [matMenuTriggerFor]="actionsMenu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </div>
          <mat-menu #actionsMenu="matMenu" class="table-action-menu">
            <a mat-menu-item *ngFor="let action of actionDefinitions" (click)="action.callback(row)" matTooltip="{{action?.tooltip}}">
              <i class="{{action.icon}} {{action.styleClass}}"></i>
              &nbsp;
              <span class="{{action.styleClass}}">{{action.text | i18next}}</span>
            </a>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="tableDefinitionHeaders"></mat-header-row>

      <mat-row *matRowDef="let row; columns: tableDefinitionHeaders" (click)="rowClickedAction(row);" class="{{hasRowAction ? 'table-row-action': 'table-row-no-action'}}"></mat-row>

    </mat-table>

    <mat-paginator [length]="getTotalCount()" [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>

</div>
