import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Application = {
  __typename?: 'Application';
  color?: Maybe<Scalars['String']['output']>;
  companiesIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  idpIdentifier: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isMobile: Scalars['Boolean']['output'];
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  loginUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  role?: Maybe<Array<Maybe<UserType>>>;
  url: Scalars['String']['output'];
};

export type ApplicationInput = {
  appType: ApplicationType;
  callbackUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  companiesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  idpName: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  loginUrl?: InputMaybe<Scalars['String']['input']>;
  logoutUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  userTypes: Array<UserTypeInput>;
};

export type ApplicationPermissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum ApplicationType {
  Mobile = 'MOBILE',
  WebApp = 'WEB_APP'
}

export type Area = {
  __typename?: 'Area';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']['output']>;
  areas?: Maybe<Array<Maybe<Area>>>;
  branding?: Maybe<Scalars['String']['output']>;
  brandingEnabled?: Maybe<Scalars['Boolean']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idpIdentifier?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['String']['output']>;
};

export type CompanyAreaInput = {
  areaIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyId: Scalars['String']['input'];
};

export type CompanyBranding = {
  logo: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
  primaryFontColor: Scalars['String']['input'];
  secondaryColor: Scalars['String']['input'];
  secondaryFontColor: Scalars['String']['input'];
};

export type License = {
  __typename?: 'License';
  company: Company;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  licenseType: LicenseType;
  machine?: Maybe<Machine>;
  name: Scalars['String']['output'];
  parameters?: Maybe<Scalars['String']['output']>;
};

export type LicenseType = {
  __typename?: 'LicenseType';
  applicationId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parametersDefinition?: Maybe<Scalars['String']['output']>;
};

export type Machine = {
  __typename?: 'Machine';
  area?: Maybe<Area>;
  code?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  licenses?: Maybe<Array<Maybe<License>>>;
  machineType?: Maybe<MachineType>;
  name?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  xPosition?: Maybe<Scalars['Float']['output']>;
  yPosition?: Maybe<Scalars['Float']['output']>;
};

export type MachineStatistics = {
  __typename?: 'MachineStatistics';
  count?: Maybe<Scalars['Int']['output']>;
  machineType?: Maybe<Scalars['String']['output']>;
};

export type MachineType = {
  __typename?: 'MachineType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignLicenseToMachine?: Maybe<Scalars['Boolean']['output']>;
  deleteArea?: Maybe<Scalars['Boolean']['output']>;
  deleteCompany?: Maybe<Scalars['Boolean']['output']>;
  deleteLicense?: Maybe<Scalars['Boolean']['output']>;
  deleteMachine?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  insertArea: Area;
  insertCompany: Company;
  insertLicense: License;
  insertMachine: Machine;
  insertUser: User;
  removeLicenseFromMachine?: Maybe<Scalars['Boolean']['output']>;
  updateArea: Area;
  updateCompany?: Maybe<Company>;
  updateMachine: Machine;
  updateUser: User;
};


export type MutationAssignLicenseToMachineArgs = {
  licenseId: Scalars['String']['input'];
  machineId: Scalars['String']['input'];
};


export type MutationDeleteAreaArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteLicenseArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMachineArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationInsertAreaArgs = {
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  location: Scalars['String']['input'];
  region: Scalars['String']['input'];
};


export type MutationInsertCompanyArgs = {
  address: Scalars['String']['input'];
  applicationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  branding?: InputMaybe<CompanyBranding>;
  brandingEnabled: Scalars['Boolean']['input'];
  businessName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  vat: Scalars['String']['input'];
};


export type MutationInsertLicenseArgs = {
  companyId: Scalars['String']['input'];
  licenseTypeId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parameters?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInsertMachineArgs = {
  areaId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  machineTypeId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  xPosition?: InputMaybe<Scalars['Float']['input']>;
  yPosition?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationInsertUserArgs = {
  companies: Array<InputMaybe<CompanyAreaInput>>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  userTypes?: InputMaybe<Array<UserAccountUserTypeInput>>;
};


export type MutationRemoveLicenseFromMachineArgs = {
  licenseId: Scalars['String']['input'];
  machineId: Scalars['String']['input'];
};


export type MutationUpdateAreaArgs = {
  city: Scalars['String']['input'];
  id: Scalars['String']['input'];
  location: Scalars['String']['input'];
  region: Scalars['String']['input'];
};


export type MutationUpdateCompanyArgs = {
  address: Scalars['String']['input'];
  applicationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  branding?: InputMaybe<CompanyBranding>;
  brandingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  vat: Scalars['String']['input'];
};


export type MutationUpdateMachineArgs = {
  areaId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
  machineTypeId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  xPosition?: InputMaybe<Scalars['Float']['input']>;
  yPosition?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateUserArgs = {
  companies: Array<InputMaybe<CompanyAreaInput>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  userTypes?: InputMaybe<Array<UserAccountUserTypeInput>>;
};

export enum OrderType {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageInput = {
  begins?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderType>;
  orderCol?: InputMaybe<Scalars['String']['input']>;
};

export type Pageable = Application | Area | Company | License | Machine | User;

export type PagedApplication = PagedResult & {
  __typename?: 'PagedApplication';
  items: Array<Application>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedArea = PagedResult & {
  __typename?: 'PagedArea';
  items: Array<Area>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedCompany = PagedResult & {
  __typename?: 'PagedCompany';
  items: Array<Company>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedLicense = PagedResult & {
  __typename?: 'PagedLicense';
  items: Array<License>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedMachines = PagedResult & {
  __typename?: 'PagedMachines';
  items: Array<Machine>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedResult = {
  items: Array<Pageable>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedUser = PagedResult & {
  __typename?: 'PagedUser';
  items: Array<User>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getApplication?: Maybe<Application>;
  getArea?: Maybe<Area>;
  getCompany?: Maybe<Company>;
  getLicense?: Maybe<License>;
  getMachine?: Maybe<Machine>;
  getMachineBySerialNumber?: Maybe<Machine>;
  getMachineStatistics?: Maybe<Array<Maybe<MachineStatistics>>>;
  getUser?: Maybe<User>;
  getUserType?: Maybe<UserType>;
  listApplications?: Maybe<PagedApplication>;
  listAreas?: Maybe<PagedArea>;
  listCompanies?: Maybe<PagedCompany>;
  listLicenseTypes?: Maybe<Array<Maybe<LicenseType>>>;
  listLicenses?: Maybe<PagedLicense>;
  listMachineTypes?: Maybe<Array<Maybe<MachineType>>>;
  listMachines?: Maybe<PagedMachines>;
  listUserTypes?: Maybe<Array<Maybe<UserType>>>;
  listUsers?: Maybe<PagedUser>;
};


export type QueryGetApplicationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAreaArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCompanyArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetLicenseArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMachineArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMachineBySerialNumberArgs = {
  serialNumber: Scalars['String']['input'];
};


export type QueryGetMachineStatisticsArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryListApplicationsArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  onlyWeb?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<PageInput>;
};


export type QueryListAreasArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCompaniesArgs = {
  page?: InputMaybe<PageInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListLicenseTypesArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListLicensesArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
};


export type QueryListMachinesArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
  areaId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
};


export type QueryListUsersArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
};

export type User = {
  __typename?: 'User';
  companies?: Maybe<Array<Maybe<Company>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idpIdentifier?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  userTypes?: Maybe<Array<Maybe<UserTypeApplication>>>;
};

export type UserAccountUserTypeInput = {
  applicationId: Scalars['String']['input'];
  userTypeId: Scalars['String']['input'];
};

export type UserType = {
  __typename?: 'UserType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type UserTypeApplication = {
  __typename?: 'UserTypeApplication';
  applicationId: Scalars['String']['output'];
  userType: UserType;
};

export type UserTypeInput = {
  id: Scalars['String']['input'];
  idpIdentifier: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<ApplicationPermissionInput>>>;
};

export type ListCompaniesQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListCompaniesQuery = { __typename?: 'Query', listCompanies?: { __typename?: 'PagedCompany', total?: number | null, items: Array<{ __typename?: 'Company', id?: string | null, idpIdentifier?: string | null, name?: string | null, description?: string | null, businessName?: string | null, vat?: string | null, address?: string | null, brandingEnabled?: boolean | null, branding?: string | null }> } | null };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany?: boolean | null };

export type ListAreasQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListAreasQuery = { __typename?: 'Query', listAreas?: { __typename?: 'PagedArea', total?: number | null, items: Array<{ __typename?: 'Area', id?: string | null, region?: string | null, city?: string | null, location?: string | null, company?: { __typename?: 'Company', id?: string | null, name?: string | null } | null }> } | null };

export type GetAreaQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetAreaQuery = { __typename?: 'Query', getArea?: { __typename?: 'Area', id?: string | null, region?: string | null, city?: string | null, location?: string | null, company?: { __typename?: 'Company', id?: string | null, name?: string | null } | null } | null };

export type DeleteAreaMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteAreaMutation = { __typename?: 'Mutation', deleteArea?: boolean | null };

export type InsertUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  userTypes?: InputMaybe<Array<UserAccountUserTypeInput> | UserAccountUserTypeInput>;
  companies: Array<InputMaybe<CompanyAreaInput>> | InputMaybe<CompanyAreaInput>;
}>;


export type InsertUserMutation = { __typename?: 'Mutation', insertUser: { __typename?: 'User', id?: string | null, idpIdentifier?: string | null, name?: string | null, surname?: string | null, email?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null } };

export type ListApplicationsQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
}>;


export type ListApplicationsQuery = { __typename?: 'Query', listApplications?: { __typename?: 'PagedApplication', total?: number | null, items: Array<{ __typename?: 'Application', id: string, idpIdentifier: string, name: string, description?: string | null, url: string, imageUrl?: string | null, color?: string | null }> } | null };

export type GetApplicationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetApplicationQuery = { __typename?: 'Query', getApplication?: { __typename?: 'Application', id: string, idpIdentifier: string, name: string, description?: string | null, url: string, imageUrl?: string | null, color?: string | null, isMobile: boolean, loginUrl?: string | null, companiesIds?: Array<string | null> | null, role?: Array<{ __typename?: 'UserType', id?: string | null, name?: string | null, parentId?: string | null } | null> | null } | null };

export type ListCompaniesByUserIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ListCompaniesByUserIdQuery = { __typename?: 'Query', listCompanies?: { __typename?: 'PagedCompany', total?: number | null, items: Array<{ __typename?: 'Company', id?: string | null, name?: string | null }> } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  userTypes?: InputMaybe<Array<UserAccountUserTypeInput> | UserAccountUserTypeInput>;
  companies: Array<InputMaybe<CompanyAreaInput>> | InputMaybe<CompanyAreaInput>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id?: string | null, idpIdentifier?: string | null, name?: string | null, surname?: string | null, email?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null, userTypes?: Array<{ __typename?: 'UserTypeApplication', applicationId: string, userType: { __typename?: 'UserType', id?: string | null, name?: string | null } } | null> | null } };

export type ListUsersQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
}>;


export type ListUsersQuery = { __typename?: 'Query', listUsers?: { __typename?: 'PagedUser', total?: number | null, items: Array<{ __typename?: 'User', id?: string | null, name?: string | null, surname?: string | null, email?: string | null, userTypes?: Array<{ __typename?: 'UserTypeApplication', applicationId: string, userType: { __typename?: 'UserType', id?: string | null, name?: string | null } } | null> | null, companies?: Array<{ __typename?: 'Company', id?: string | null, idpIdentifier?: string | null, name?: string | null, description?: string | null, businessName?: string | null, vat?: string | null, areas?: Array<{ __typename?: 'Area', id?: string | null, region?: string | null, city?: string | null, location?: string | null } | null> | null } | null> | null }> } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: boolean | null };

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id?: string | null, email?: string | null, idpIdentifier?: string | null, name?: string | null, surname?: string | null, companies?: Array<{ __typename?: 'Company', id?: string | null, areas?: Array<{ __typename?: 'Area', id?: string | null, region?: string | null, city?: string | null, location?: string | null } | null> | null } | null> | null, userTypes?: Array<{ __typename?: 'UserTypeApplication', applicationId: string, userType: { __typename?: 'UserType', id?: string | null, name?: string | null } } | null> | null } | null };

export type ListUserTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUserTypesQuery = { __typename?: 'Query', listUserTypes?: Array<{ __typename?: 'UserType', id?: string | null, name?: string | null, parentId?: string | null } | null> | null };

export const ListCompaniesDocument = gql`
    query ListCompanies($page: PageInput, $userId: String) {
  listCompanies(page: $page, userId: $userId) {
    items {
      id
      idpIdentifier
      name
      description
      businessName
      vat
      address
      brandingEnabled
      branding
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCompaniesGQL extends Apollo.Query<ListCompaniesQuery, ListCompaniesQueryVariables> {
    override document = ListCompaniesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: String!) {
  deleteCompany(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCompanyGQL extends Apollo.Mutation<DeleteCompanyMutation, DeleteCompanyMutationVariables> {
    override document = DeleteCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAreasDocument = gql`
    query ListAreas($page: PageInput, $companyId: String) {
  listAreas(page: $page, companyId: $companyId) {
    items {
      id
      region
      city
      location
      company {
        id
        name
      }
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAreasGQL extends Apollo.Query<ListAreasQuery, ListAreasQueryVariables> {
    override document = ListAreasDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAreaDocument = gql`
    query GetArea($id: String!) {
  getArea(id: $id) {
    id
    region
    city
    location
    company {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAreaGQL extends Apollo.Query<GetAreaQuery, GetAreaQueryVariables> {
    override document = GetAreaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAreaDocument = gql`
    mutation DeleteArea($id: String!) {
  deleteArea(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAreaGQL extends Apollo.Mutation<DeleteAreaMutation, DeleteAreaMutationVariables> {
    override document = DeleteAreaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertUserDocument = gql`
    mutation insertUser($email: String!, $name: String!, $surname: String!, $userTypes: [UserAccountUserTypeInput!], $companies: [CompanyAreaInput]!) {
  insertUser(
    email: $email
    name: $name
    surname: $surname
    userTypes: $userTypes
    companies: $companies
  ) {
    id
    idpIdentifier
    name
    surname
    email
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertUserGQL extends Apollo.Mutation<InsertUserMutation, InsertUserMutationVariables> {
    override document = InsertUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListApplicationsDocument = gql`
    query ListApplications($page: PageInput) {
  listApplications(page: $page) {
    items {
      id
      idpIdentifier
      name
      description
      url
      imageUrl
      color
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListApplicationsGQL extends Apollo.Query<ListApplicationsQuery, ListApplicationsQueryVariables> {
    override document = ListApplicationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetApplicationDocument = gql`
    query GetApplication($id: String!) {
  getApplication(id: $id) {
    id
    idpIdentifier
    name
    description
    url
    imageUrl
    color
    isMobile
    loginUrl
    companiesIds
    role {
      id
      name
      parentId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetApplicationGQL extends Apollo.Query<GetApplicationQuery, GetApplicationQueryVariables> {
    override document = GetApplicationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListCompaniesByUserIdDocument = gql`
    query ListCompaniesByUserId($id: String!) {
  listCompanies(userId: $id) {
    items {
      id
      name
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCompaniesByUserIdGQL extends Apollo.Query<ListCompaniesByUserIdQuery, ListCompaniesByUserIdQueryVariables> {
    override document = ListCompaniesByUserIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $name: String!, $surname: String!, $userTypes: [UserAccountUserTypeInput!], $companies: [CompanyAreaInput]!) {
  updateUser(
    id: $id
    name: $name
    surname: $surname
    userTypes: $userTypes
    companies: $companies
  ) {
    id
    idpIdentifier
    name
    surname
    email
    userTypes {
      applicationId
      userType {
        id
        name
      }
    }
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    override document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListUsersDocument = gql`
    query ListUsers($companyId: String, $page: PageInput) {
  listUsers(companyId: $companyId, page: $page) {
    items {
      id
      name
      surname
      email
      userTypes {
        applicationId
        userType {
          id
          name
        }
      }
      companies {
        id
        idpIdentifier
        name
        description
        businessName
        vat
        areas {
          id
          region
          city
          location
        }
      }
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUsersGQL extends Apollo.Query<ListUsersQuery, ListUsersQueryVariables> {
    override document = ListUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    override document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query getUser($id: String) {
  getUser(id: $id) {
    id
    email
    idpIdentifier
    name
    surname
    companies {
      id
      areas {
        id
        region
        city
        location
      }
    }
    userTypes {
      applicationId
      userType {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    override document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListUserTypesDocument = gql`
    query listUserTypes {
  listUserTypes {
    id
    name
    parentId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUserTypesGQL extends Apollo.Query<ListUserTypesQuery, ListUserTypesQueryVariables> {
    override document = ListUserTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }