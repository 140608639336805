import { IGraphqlMapper } from '../models/interfaces/I-graphql-mapper';
import { UserModel } from '../models/interfaces/user.model';

export class GetUserMapper implements IGraphqlMapper {
  user?: UserModel;
  returnFromJson(json: any): UserModel {
    const value = json['getUser'];
    this.user = {
      userId: value['id'],
      email: value['email'],
      idpIdentifier: value['idp_identifier'],
      name: value['name'],
      surname: value['surname'],
      companies: value['companies'],
      userTypes: value['userTypes'].length > 0 ? value['userTypes'] : [],
    };
    return this.user;
  }
}
