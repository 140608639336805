<h1>{{ 'PROFILE' | i18next }}</h1>
<div class="user">
  <h2>{{ 'EMAIL' | i18next }}</h2>
  <div class="user-data">
    <span>
      <i class="fa fa-regular fa-envelope"></i>
      {{user.email}}
    </span>
  </div>
  <br>
  <h2>{{ 'NAME' | i18next }}</h2>
  <div class="user-data">
    <span>
      <i class="fa fa-regular fa-circle-user"></i>
      {{user.name}}
    </span>
  </div>
</div>
