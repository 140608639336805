import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProviderService } from '../../core/provider.service';
import {companyChangedSubject} from "../navbar/navbar.component";
import {skip} from "rxjs";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() ssToggle = false;
  isOperatorOrAdmin = false;
  isAdmin = false;
  isAdminCompanySelected = false;
  private environment = environment;

  constructor(private providerService: ProviderService) {}

  navigateTo(href: string): void {
    this.providerService.utilService.navigateTo(href);
  }

  async ngOnInit(): Promise<void> {
    const userAndRole = await this.providerService.roleService.getUserAndRole();
    const userType = userAndRole.userTypes.find(
      (value) =>
        value.applicationId === this.environment.purelatteApplication.id
    );
    this.isAdminCompanySelected = this.providerService.companyService.getIsAdminSelected();
    companyChangedSubject.pipe(skip(1)).subscribe((company) => {
      this.isAdminCompanySelected = company.name === 'Superadmin Evoca';
    });
    if (userType) {
      this.isAdmin =
        userType.userType.name.toLowerCase() === 'superadmin_evoca';
      this.isOperatorOrAdmin =
        userType.userType.name !== 'technician' &&
        userType.userType.name !== 'user';
    }
  }
}
