<div class="sidebar {{ssToggle ? 'set-away' : ''}}">

  <ng-container class="nav-menu">
    <ng-container *ngIf="isOperatorOrAdmin">
      <button type="button" class="nav-item" mat-button (click)="navigateTo('/app/users')">
        <mat-icon>supervised_user_circle</mat-icon>
        <span>{{ 'USERS' | i18next }}</span>
      </button>
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container *ngIf="isAdmin && isAdminCompanySelected">
      <button type="button" class="nav-item" mat-button (click)="navigateTo('/app/lineaLatte')">
        <i class="fa-solid fa-cow"></i>
        <span>{{ 'LINEA_LATTE' | i18next }}</span>
      </button>
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container *ngIf="isAdmin && isAdminCompanySelected">
      <button type="button" class="nav-item" mat-button (click)="navigateTo('/app/bib')">
        <i class="fa-solid fa-box"></i>
        <span>{{ 'LINEA_BIB' | i18next }}</span>
      </button>
      <mat-divider></mat-divider>
    </ng-container>
  </ng-container>

  <div class="spacer"></div>

  <div class="copyright-version">
    <p>
      <span>&copy;beSharp 2023. All right reserved.</span>
      <br>
      <small>V0.0.1</small>
    </p>
  </div>
</div>
