import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from './app-initializer';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { customPaginator } from './core/form-utils/custom-paginator/custom-paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { GraphQLModule } from './graphql.module';
import { ApolloInterceptor } from './apollo-interceptor';
import { CognitoIamAuthenticatorService } from './core/net-utils/api-authorization/cognito-iam-authenticator.service';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    GraphQLModule,
    MatMenuModule,
    I18NextModule.forRoot(),
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: customPaginator() },
    I18N_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApolloInterceptor,
      multi: true,
      deps: [CognitoIamAuthenticatorService],
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
