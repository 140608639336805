import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NetworkService } from '../../../core/net-utils/network.service';
import { CreateLineaLatteMapper } from '../../linea-latte/linea-latte-mappers';
import { LogLevel } from '../../../models/log-level';

@Component({
  selector: 'app-modify-linea-latte',
  templateUrl: './modify-linea-latte.component.html',
  styleUrls: ['./modify-linea-latte.component.scss'],
})
export class ModifyLineaLatteComponent {
  backendUrl = environment.api.endpointPurelatte + 'linea-latte';
  form = new FormGroup({
    pk: new FormControl('', [Validators.required]),
    serialNumber: new FormControl('', [Validators.required]),
    expirationDate: new FormControl(new Date(), [Validators.required]),
    producerCode: new FormControl('', [Validators.required]),
    lastState: new FormControl('', [Validators.required]),
  });
  isAdmin = true;
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<ModifyLineaLatteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    private networkService: NetworkService
  ) {
    this.initializeVariables();
    this.form.controls.serialNumber.disable();
    this.form.controls.expirationDate.disable();
    this.form.controls.producerCode.disable();
  }

  formValid(): boolean {
    return this.form.valid;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  initializeVariables(): void {
    const lineaLatte = this.data.row;
    this.form.controls.pk.setValue(lineaLatte.pk);
    this.form.controls.serialNumber.setValue(lineaLatte.serialNumber);
    this.form.controls.expirationDate.setValue(
      new Date(this.validDate(lineaLatte.expirationDate))
    );
    this.form.controls.producerCode.setValue(lineaLatte.producerCode);
    this.form.controls.lastState.setValue(lineaLatte.state);

    this.form.markAllAsTouched();
    this.form.controls.pk.markAsDirty();
    this.form.controls.serialNumber.markAsDirty();
    this.form.controls.expirationDate.markAsDirty();
    this.form.controls.producerCode.markAsDirty();
    this.form.controls.lastState.markAsDirty();
  }

  validDate(s: any): Date {
    const parts = s.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  async modifyLineaLatte(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const mapper = new CreateLineaLatteMapper();
        mapper.serialNumber = this.form.controls.serialNumber.value ?? '';
        mapper.expirationDate =
          this.form.controls.expirationDate.value ?? new Date();
        mapper.producerCode = this.form.controls.producerCode.value ?? '';
        mapper.lastState = this.form.controls.lastState.value ?? '';
        await this.networkService?.put(this.backendUrl, mapper, mapper);
        this.loading = false;
        this.data.callback();
        this.closeModal();
        this.providerService.utilService.showMessage(
          'Modified successfully',
          LogLevel.success
        );
      } catch (e: any) {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      }
    }
  }
}
