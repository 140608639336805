import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatorUiComponent } from './form-utils/validator-ui/validator-ui.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TableUiComponent } from './form-utils/table/table-ui/table-ui.component';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18NextModule } from 'angular-i18next';
import { GraphqlTableUiComponent } from './form-utils/graphql-table/graphql-table-ui/graphql-table-ui.component';
import { SelectInfiniteScrollComponent } from './form-utils/select-infinite-scroll/select-infinite-scroll.component';
import { OptionsScrollDirective } from './form-utils/select-infinite-scroll/options-scroll.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SelectInfiniteScrollMultiComponent } from './form-utils/select-infinite-scroll-multi/select-infinite-scroll-multi.component';
import { MatSelectModule } from '@angular/material/select';
import { OptionsScrollMultiDirective } from './form-utils/select-infinite-scroll-multi/options-scroll-multi.directive';

@NgModule({
  declarations: [
    ValidatorUiComponent,
    TableUiComponent,
    GraphqlTableUiComponent,
    SelectInfiniteScrollComponent,
    SelectInfiniteScrollMultiComponent,
    OptionsScrollDirective,
    OptionsScrollMultiDirective,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatSnackBarModule,
    HttpClientModule,
    MatInputModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    I18NextModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatSelectModule,
  ],
  exports: [
    ValidatorUiComponent,
    TableUiComponent,
    GraphqlTableUiComponent,
    SelectInfiniteScrollComponent,
    SelectInfiniteScrollMultiComponent,
    OptionsScrollDirective,
  ],
})
export class CoreModule {}
