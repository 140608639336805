import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { NetworkService } from '../../../core/net-utils/network.service';
import { CreateLineaLatteMapper } from '../../linea-latte/linea-latte-mappers';
import { LogLevel } from '../../../models/log-level';

@Component({
  selector: 'app-create-linea-latte',
  templateUrl: './create-linea-latte.component.html',
  styleUrls: ['./create-linea-latte.component.scss'],
})
export class CreateLineaLatteComponent {
  backendUrl = environment.api.endpointPurelatte + 'linea-latte';
  form = new FormGroup({
    serialNumber: new FormControl('', [Validators.required]),
    expirationDate: new FormControl(new Date(), [Validators.required]),
    producerCode: new FormControl('', [Validators.required]),
    lastState: new FormControl('', [Validators.required]),
  });
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<CreateLineaLatteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    private networkService: NetworkService
  ) {}

  formValid(): boolean {
    return this.form.valid;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async createLineaLatte(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const mapper = new CreateLineaLatteMapper();
        mapper.serialNumber = this.form.controls.serialNumber.value ?? '';
        mapper.expirationDate =
          this.form.controls.expirationDate.value ?? new Date();
        mapper.producerCode = this.form.controls.producerCode.value ?? '';
        mapper.lastState = this.form.controls.lastState.value ?? '';
        await this.networkService?.put(this.backendUrl, mapper, mapper);
        this.loading = false;
        this.data.callback();
        this.closeModal();
        this.providerService.utilService.showMessage(
          'Create LL successfully!',
          LogLevel.success
        );
      } catch (e: any) {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      }
    }
  }
}
