<div class="loader">
  <div class="loader-element">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <img src="assets/images/logo-color.png" alt="logo" title="logo">
    <span>{{'AUTHENTICATE.MESSAGE' | i18next}}</span>
  </div>
</div>
