import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as Apollo from 'apollo-angular';
import {
  exhaustMap,
  Observable,
  scan,
  startWith,
  Subject,
  Subscriber,
} from 'rxjs';
import { IGraphqlPagedMapper } from 'src/app/models/interfaces/I-graphql-paged-mapper';
import { ProviderService } from '../../provider.service';
import { takeWhile, tap } from 'rxjs/operators';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { AntiMemLeak } from '../../../shared/abstract-classes/anti-mem-leak';
import { CompanyModel } from '../../../companies/models/company.model';

@Component({
  selector: 'app-select-infinite-scroll-multi',
  templateUrl: './select-infinite-scroll-multi.component.html',
  styleUrls: ['./select-infinite-scroll-multi.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class SelectInfiniteScrollMultiComponent extends AntiMemLeak implements OnInit {
  @Input()
  icon = '';
  @Input()
  label = '';
  @Input()
  dataShowSelectors = [''];
  @Input()
  dataCompareSelectors: string[] = [];
  @Input()
  query?: Apollo.Query<any, any>;
  @Input()
  mapper?: IGraphqlPagedMapper;
  @Input()
  params?: { [key: string]: string };
  @Input()
  selectedData?: any[];
  @Input()
  selectedDataChanged?: Observable<any>;
  @Input()
  disabled?: boolean;
  @Input()
  disabledChanged?: Observable<boolean>;
  @Input()
  noneOption = false;
  @Output()
  selectionChanged = new EventEmitter<any>();
  loading = false;
  pageSize = 10;
  total = 0;
  data$: Observable<any[]> | undefined;
  selectedCompany?: CompanyModel;
  public exFormGroup = new FormGroup({
    selectedOptions: new FormControl([{}] as any),
  });
  private nextPage$ = new Subject();
  private wasNoneLastSelected = false;
  private firstGetData = true;

  constructor(private providerService: ProviderService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.data$ = this.getItems(0);
    if (this.disabledChanged) {
      this.subscriptions.add(
        this.disabledChanged.subscribe((value) => {
          this.disabled = value;
          if (value) {
            this.exFormGroup.controls.selectedOptions.disable();
          } else {
            this.exFormGroup.controls.selectedOptions.enable();
          }
        })
      );
    }
    this.subscriptions.add(
      this.exFormGroup.controls.selectedOptions.valueChanges.subscribe(
        (value) => {
          if (this.noneOption) {
            const selectedNoneOption = value.find(
              (item: any) => item.id === 'none'
            );
            if (selectedNoneOption && value.length > 1) {
              if (!this.wasNoneLastSelected) {
                this.wasNoneLastSelected = true;
                this.exFormGroup.controls.selectedOptions.setValue(
                  [selectedNoneOption],
                  { emitEvent: true }
                );
              } else {
                (value as any[]).splice(
                  value.findIndex((item: any) => item === selectedNoneOption),
                  1
                );
                this.wasNoneLastSelected = false;
                this.exFormGroup.controls.selectedOptions.setValue(value, {
                  emitEvent: true,
                });
              }
            } else {
              if (selectedNoneOption) {
                this.wasNoneLastSelected = true;
              }
              this.selectionChanged.emit(value);
            }
          } else {
            this.selectionChanged.emit(value);
          }
        }
      )
    );
    if (this.selectionChanged) {
      this.subscriptions.add(
        this.selectionChanged.subscribe((value) => {
          this.exFormGroup.controls.selectedOptions.setValue(value, {
            emitEvent: false,
          });
        })
      );
    }

    if (this.selectedData) {
      this.selectedData.forEach((value) => {
        if (value.id === 'none') {
          value[this.dataShowSelectors[0]] = _(
            'SELECT_INFINITE_SCROLL_MULTI.NONE_OPTION_VALUE'
          );
        }
      });
      this.exFormGroup.controls.selectedOptions.setValue(this.selectedData, {
        emitEvent: false,
      });
    }

    this.subscriptions.add(
      this.selectedDataChanged?.subscribe((value) => {
        this.exFormGroup.controls.selectedOptions.setValue(value);
      })
    );

    if (this.disabled) {
      this.exFormGroup.controls.selectedOptions.disable();
    }
    let currentPage = 0;
    this.data$ = this.nextPage$.pipe(
      startWith(currentPage),
      // Note: Until the backend responds, ignore NextPage requests.
      exhaustMap((__) => this.getItems(currentPage)),
      tap(() => currentPage++),
      takeWhile((p: any[]) => p.length > 0),
      scan((allData: any, newData: any) => allData.concat(newData), [])
    );
    this.loading = false;
  }

  onScroll(): void {
    // Note: This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next('');
  }

  getItems(page: number): Observable<any[]> {
    return new Observable((subscriber: Subscriber<any[]>) => {
      const variables: {
        [key: string]: any;
      } = {};
      variables['page'] = {
        filter: '',
        limit: this.pageSize,
        orderCol: '',
        begins: page * this.pageSize,
        order: 'asc',
      };
      for (const key in this.params) {
        if (Object.hasOwn(this.params, key)) {
          variables[key] = this.params[key];
        }
      }
      if (this.query && this.mapper) {
        this.providerService.graphqlService
          .fetch<any>(this.query, variables, this.mapper)
          .then((result) => {
            this.total = result.total;
            if (this.noneOption && this.firstGetData) {
              const option: { [k: string]: any } = {};
              option['id'] = 'none';
              option[this.dataShowSelectors[0]] = _(
                'SELECT_INFINITE_SCROLL_MULTI.NONE_OPTION_VALUE'
              );
              result.items.unshift(option);
              this.firstGetData = false;
            }
            subscriber.next(result.items);
            subscriber.complete();
          });
      }
    });
  }

  compareWith = (o1: any, o2: any): boolean => this.compareObjects(o1, o2);

  compareObjects(o1: any, o2: any): boolean {
    if (this.dataCompareSelectors && this.dataCompareSelectors.length > 0) {
      let comparable = true;
      this.dataCompareSelectors.forEach((value) => {
        if (!(o1[value] === o2[value])) {
          comparable = false;
        }
      });
      return comparable;
    } else {
      if (o1 && o2) {
        return JSON.stringify(o1) === JSON.stringify(o2);
      }
    }
    return false;
  }

  getOptionsShowValue(): string {
    let result = '';
    this.exFormGroup.controls.selectedOptions.value.forEach((value: any) => {
      result += this.getOptionShowValue(value) + ' - ';
    });
    result = result.trim();
    return result.substring(0, result.length - 2);
  }

  resolveObjectProperty(path: string, obj: any): string | null {
    return path
      .split('.')
      .reduce((prev, curr) => (prev ? prev[curr] : null), obj || self);
  }

  getOptionShowValue(option: any): any {
    if (option) {
      let toReturn = '';
      this.dataShowSelectors.forEach((value) => {
        const objectValue = this.resolveObjectProperty(value, option);
        if (objectValue) {
          toReturn +=
            this.providerService.translationService.translate(objectValue);
          toReturn += ' - ';
        }
      });
      const result = toReturn.trim();
      return result.substring(0, result.length - 2);
    }
  }
}
