import { ModifyUserCompanyModel } from './modify-user.models';
import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';
import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';
import { UserModel } from '../../technicians/models/technician.model';
import {
  ApplicationModel,
  FullApplicationModel,
} from '../../../../../shared/models/applications-models';

export class ListCompaniesByUserIdMapper implements IGraphqlPagedMapper {
  items: ModifyUserCompanyModel[] = [];
  total = 0;

  returnFromJson(json: any): {
    items: ModifyUserCompanyModel[];
    total: number;
  } {
    this.items = [];
    const pagedCompanies = json['listCompanies'];
    pagedCompanies['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        name: value['name'],
      });
    });
    this.total = pagedCompanies['total'];
    return { items: this.items, total: this.total };
  }
}

export class GetApplicationReducedMapper implements IGraphqlMapper {
  application?: ApplicationModel;

  returnFromJson(json: any): ApplicationModel {
    const value = json['getApplication'];
    this.application = {
      applicationId: value['id'],
      applicationName: value['name'],
      url: value['url'],
      imageUrl: value['imageUrl'],
      color: value['color'],
      description: value['description'],
    };
    return this.application;
  }
}

export class GetApplicationMapper implements IGraphqlMapper {
  application?: FullApplicationModel;

  returnFromJson(json: any): FullApplicationModel {
    const getApplication = json['getApplication'];
    this.application = {
      id: getApplication['id'],
      idpIdentifier: getApplication['idpIdentifier'],
      name: getApplication['name'],
      description: getApplication['description'],
      url: getApplication['url'],
      imageUrl: getApplication['imageUrl'],
      color: getApplication['color'],
      auth0Name: getApplication['auth0Name'],
      auth0Description: getApplication['auth0Description'],
      isMobile: getApplication['isMobile'],
      loginUrl: getApplication['loginUrl'],
      callbackUrls: getApplication['callbackUrls'],
      logoutUrls: getApplication['logoutUrls'],
      companiesIds: getApplication['companiesIds'],
      roles: getApplication['role'] ? JSON.parse(getApplication['role']) : [],
    };
    return this.application;
  }
}

export class UpdateApplicationMapper implements IGraphqlMapper {
  application?: FullApplicationModel;

  returnFromJson(json: any): FullApplicationModel {
    const getApplication = json['updateApplication'];
    this.application = {
      id: getApplication['id'],
      idpIdentifier: getApplication['idpIdentifier'],
      name: getApplication['name'],
      description: getApplication['description'],
      url: getApplication['url'],
      imageUrl: getApplication['imageUrl'],
      color: getApplication['color'],
      auth0Name: getApplication['auth0Name'],
      auth0Description: getApplication['auth0Description'],
      isMobile: getApplication['isMobile'],
      loginUrl: getApplication['loginUrl'],
      callbackUrls: getApplication['callbackUrls'],
      logoutUrls: getApplication['logoutUrls'],
      companiesIds: getApplication['companies'],
      roles: getApplication['role'] ? JSON.parse(getApplication['role']) : [],
    };
    return this.application;
  }
}

export class ModifyUserMapper implements IGraphqlMapper {
  user?: UserModel;

  returnFromJson(json: any): UserModel {
    const value = json['updateUser'];
    this.user = {
      userId: value['id'],
      email: value['email'],
      idpIdentifier: value['idpIdentifier'],
      name: value['name'],
      surname: value['surname'],
      userTypes: value['userTypes'],
      companies: value['companies'],
    };
    return this.user;
  }
}
