import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import {
  CognitoIamAuthenticatorService,
  RequestType,
} from './core/net-utils/api-authorization/cognito-iam-authenticator.service';
import { HttpVerb } from './models/http-verb';
import { environment } from '../environments/environment';

@Injectable()
export class ApolloInterceptor implements HttpInterceptor {
  constructor(private cognitoService: CognitoIamAuthenticatorService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/graphql') > -1) {
      //add necessary headers for authentication
      return from(this.getRequest(req)).pipe(
        switchMap((newRequest) => {
          const newReq = req.clone({
            setHeaders: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              authorization: newRequest.headers['authorization'],
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'x-amz-content-sha256':
                newRequest.headers['x-amz-content-sha256'],
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'x-amz-date': newRequest.headers['x-amz-date'],
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'x-amz-security-token':
                newRequest.headers['x-amz-security-token'],
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'x-application-id': environment.purelatteApplication.id,
            },
          });
          return next.handle(newReq);
        })
      );
    }
    // If it's not a graphql request, just give it to the next handler.
    return next.handle(req);
  }

  private async getRequest(req: HttpRequest<any>): Promise<any> {
    return await this.cognitoService.getRequest(
      req.url,
      HttpVerb.post,
      {},
      JSON.stringify(req.body),
      {},
      RequestType.graphql
    );
  }
}
