import { Injectable } from '@angular/core';
import { ProviderService } from '../../provider.service';
import { IExportDynamoMapper } from '../../../models/interfaces/i-export-dynamo-mapper';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private totalCount = 0;
  constructor(private providerService: ProviderService) {}

  getTotalCount(): number {
    return this.totalCount;
  }

  async find(
    url: string,
    parentId: number,
    filter = '',
    sortItem = '',
    sortOrder = 'asc',
    lastEvaluatedKey: any = undefined,
    limit = 3,
    mapper: IExportDynamoMapper
  ): Promise<IExportDynamoMapper> {
    let params;
    if (lastEvaluatedKey !== undefined) {
      params = {
        filter,
        lastEvaluatedKey: JSON.stringify(lastEvaluatedKey),
        limit: limit.toString(),
        parentId: parentId.toString(),
        sortItem,
        sortOrder,
      };
    } else {
      params = {
        filter,
        limit: limit.toString(),
        parentId: parentId.toString(),
        sortItem,
        sortOrder,
      };
    }

    try {
      const result = await this.providerService.networkService.get(
        url,
        mapper,
        params
      );
      this.totalCount = mapper.total;
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
