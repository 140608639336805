import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';
import { GraphQLError } from 'graphql/error';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor() {}

  async fetch<T>(
    query: Apollo.Query<any, any>,
    variables: any,
    mapper: IGraphqlMapper
  ): Promise<T> {
    const result = await firstValueFrom(
      query.fetch(variables, { errorPolicy: 'all' })
    );
    if (result.errors) {
      this.handleErrors(result.errors);
    }
    if (Object.values(result.data)[0] === null) {
      throw new Error('No result found');
    }
    return mapper.returnFromJson(result.data);
  }

  async mutate<T>(
    mutation: Apollo.Mutation<any, any>,
    variables: any,
    mapper: IGraphqlMapper
  ): Promise<T> {
    const result = await firstValueFrom(
      mutation.mutate(variables, { errorPolicy: 'all' })
    );
    if (result.errors) {
      this.handleErrors(result.errors);
    }
    if (Object.values(result.data)[0] === null) {
      throw new Error('Something went wrong');
    }
    return mapper.returnFromJson(result.data);
  }

  private handleErrors(errors: readonly GraphQLError[]) {
    if (errors.length > 0) {
      let stringToShow = '';
      errors.forEach((err: any) => {
        stringToShow = stringToShow.concat(err.message, ' ');
      });
      throw new Error(stringToShow.trim());
    }
  }
}
